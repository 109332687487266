import { ROLES } from "../features/Constant";

/**
 *
 * @param {number} standard : 2-전부허용, 3-관리자허용, 4-가이드팀장급허용
 * @param {number} currentRole : 현재 로그인된 역할
 * @returns
 */

export async function allowByPosition(standard, currentRole) {
  let result = false;
  // 2 : 전부
  // 3: 관리자
  // 4: 가이드 팀장(관리자 X)
  // 5: ITS or Admin
  // 6: God (개발자)
  if (standard === ROLES.GUIDE) {
    if (currentRole !== undefined) result = true;
  } else if (standard === ROLES.ADMIN) {
    if (currentRole === standard || currentRole === ROLES.GOD) result = true;
  } else if (standard === ROLES.LEADERGUIDE) {
    if (currentRole > ROLES.GUIDE) result = true;
  } else if (standard === ROLES.ITS) {
    if (currentRole === ROLES.ADMIN || currentRole === ROLES.ITS || currentRole === ROLES.GOD) result = true;
  } else if (standard === ROLES.GOD) {
    if (currentRole === ROLES.GOD) return true;
  }
  return result;
}

export function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  return date
}

export function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date
}

export function convertDateToYYYYMMDD(date) {
  return date.getUTCFullYear() + "-" + (date.getUTCMonth() + 1) + "-" + date.getUTCDate();
}

export function formatNumber(value) {
  if (value === undefined || value === null) return "";
  else {
    value = value.toString().replace(/,/g, "");
    let result = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
  }
}

export function formatNumberDigit(value) {
  if (value === undefined || value === null) return "";
  else {
    let result = "";
    if (value < 10) result = "0" + value;
    else result = value;
    return result;
  }
}

export function calculateString(value) {
  let token = tokenizationStr(value);
  let postfix = infixToPostfix(token);
  let result = computePostfix(postfix);

  // 빈값 0으로 설정
  if (result === undefined || result === null || isNaN(result))
    result = 0;

  return result;
}

// 우선순위 확인
function priorityValue(char) {
  switch (char) {
    case '*':
    case '/':
      return 2;
    case '+':
    case '-':
      return 1;
    default:
      return 0;
  }
}

// 1. 스트링 토큰화
function tokenizationStr(value) {
  let result = [];
  let numberStr = '';

  if (value === null || value === undefined)
    value = "0";

  if (typeof (value) === "number") {
    result.push(String(value));
  }

  for (var i = 0; i < value.length; i++) {
    // 숫자인 경우
    if (value[i] !== '(' && value[i] !== ')' && value[i] !== '+' && value[i] !== '-' && value[i] !== '*' && value[i] !== '/') {
      numberStr += value[i];
    }
    // 연산자인 경우
    else {
      //
      if (numberStr.length > 0) {
        result.push(Number(numberStr));
        numberStr = '';
      }

      // 현재 들어온 연산자가 +, - 면 앞에 연산자 확인
      var topOperator = result[result.length - 1];
      var currOperator = value[i];

      // 4가지 경우
      // +, - => - 
      // -, + => -
      // -, - => +
      // +, +, => +
      if (((topOperator === '+') && currOperator === '-')) {
        result.pop();
        result.push('-');
      }
      else if ((topOperator === '-' && currOperator === '+') || (topOperator === '+' && currOperator === '+')) {
        // skip
      }
      else if ((topOperator === '-' && currOperator === '-')) {
        result.pop();
        result.push('+');
      }
      // 일반적인 경우 push
      else
        result.push(value[i]);
    }
  }

  // 숫자로만 스트링
  if (numberStr.length > 0) {
    result.push(Number(numberStr));
    numberStr = '';
  }

  return result;
}

// 2. 중위 표현식 > 후위 표현식
function infixToPostfix(token) {
  let calStack = [];
  let outStack = [];

  for (let i = 0; i < token.length; i++) {
    // 1. 연산자 or 피연산자 확인
    // 1-1. 연산자인 경우
    if (token[i] === '(' || token[i] === ')' || token[i] === '*' || token[i] === '/' || token[i] === '+' || token[i] === '-') {
      // 2. 괄호, 나머지 구분
      // 괄호는 최종결과에 포함시키지 않음
      // 2-1. 여는 괄호인 경우
      if (token[i] === '(') {
        calStack.push(token[i]);
      }
      // 2-2. 닫는 괄호인 경우
      else if (token[i] === ')') {
        // 여는 괄호 나올때 까지 pop
        while (calStack[calStack.length - 1] !== '(') {
          outStack.push(calStack.pop());
        }
        // 여는 괄호 pop
        calStack.pop();
      }
      // 2-3. 나머지인 경우
      else {
        // 3. 스택 비어있으면 push or 상단 요소 괄호인 경우 무조건 push
        if (calStack.length === 0 || calStack[calStack.length - 1] === '(') {
          calStack.push(token[i]);
        }
        // 스택이 비어있지 않으면 우선순위 비교
        else {
          // 우선순위 비교
          // 상단 요소 vs 현재 요소

          // 조건
          // 현재 요소가 크면 push
          // 작거나 같으면 상단 요소 pop 후 현재 요소 push
          if (priorityValue(calStack[calStack.length - 1]) < priorityValue(token[i])) {
            calStack.push(token[i]);
          }
          else {
            outStack.push(calStack.pop());
            calStack.push(token[i]);
          }
        }
      }
    }
    // 1-2. 피연산자인 경우 출력
    else {
      outStack.push(token[i]);
    }
  }

  // 남은 스택 출력
  while (calStack.length !== 0) {
    outStack.push(calStack.pop());
  }

  return outStack;
}

// 3. 후위표현식 계산
function computePostfix(array) {
  let tmpStack = [];
  let first = "";
  let second = "";

  for (var i = 0; i < array.length; i++) {
    switch (array[i]) {
      case '+':
        // 구분
        first = tmpStack.pop();
        second = tmpStack.pop();

        if (first === undefined)
          first = 0;

        if (second === undefined)
          second = 0;

        tmpStack.push(first + second);
        break;
      case '-':
        // 구분
        first = tmpStack.pop();
        second = tmpStack.pop();

        if (first === undefined)
          first = 0;

        if (second === undefined)
          second = 0;

        tmpStack.push(-(first - second));
        break;
      case '*':
        tmpStack.push(tmpStack.pop() * tmpStack.pop());
        break;
      case '/':
        let rv = tmpStack.pop();
        tmpStack.push(tmpStack.pop() / rv);
        break;
      default:
        tmpStack.push(Number(array[i]))
        break;
    }
  }

  return tmpStack.pop();
}

export function showContent(url) {
  if (url !== null && url !== undefined) {
    if (url.endsWith(".jpg") || url.endsWith(".png") || url.endsWith(".pdf"))
      window.open(url, "_blank");
    else window.open("https://docs.google.com/gview?url=" + url, "_blank");
  } else {
  }
}

export function getMonthToInt(str) {
  let result = 0;
  switch (str) {
    case "JAN":
      result = 1;
      break;
    case "FEB":
      result = 2;
      break;
    case "MAR":
      result = 3;
      break;
    case "APR":
      result = 4;
      break;
    case "MAY":
      result = 5;
      break;
    case "JUN":
      result = 6;
      break;
    case "JUL":
      result = 7;
      break;
    case "AUG":
      result = 8;
      break;
    case "SEP":
      result = 9;
      break;
    case "OCT":
      result = 10;
      break;
    case "NOV":
      result = 11;
      break;
    case "DEC":
      result = 12;
      break;
  }

  return result;
}

export function getMonthToStr(int) {
  let result = "";
  switch (int) {
    case 0:
      result = "JAN";
      break;
    case 1:
      result = "FEB";
      break;
    case 2:
      result = "MAR";
      break;
    case 3:
      result = "APR";
      break;
    case 4:
      result = "MAY";
      break;
    case 5:
      result = "JUN";
      break;
    case 6:
      result = "JUL";
      break;
    case 7:
      result = "AUG";
      break;
    case 8:
      result = "SEP";
      break;
    case 9:
      result = "OCT";
      break;
    case 10:
      result = "NOV";
      break;
    case 11:
      result = "DEC";
      break;
  }

  return result;
}

export function mimeTypeToExt(mimetype) {
  switch (mimetype) {
    case "image/jpg":
    case "image/jpeg":
      return ".jpg"
    case "image/png":
      return ".png"
    case "image/bmp":
      return ".bmp"
    case "image/gif":
      return ".gif"
    case "application/pdf":
      return ".pdf"
  }
}