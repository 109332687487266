import React, { useState } from "react";
import * as HTTPManager from "../../features/HTTPManager";
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import * as StateList from "../../features/StateList";
import * as Utils from "../../features/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { faSpinner, faCloudDownload, } from "@fortawesome/free-solid-svg-icons";
import { TOURINFO_COLOR_SET_IN } from "../../features/Constant";
import Dropdown from "../Dropdown";
import ReservationHotelDialog from "./ReservationHotelDialog";
import ReservationBusDialog from "./ReservationBusDialog";
import ReservationGuideDialog from "./ReservationGuideDialog";
import ModifyDialog from "./ModifyDialog";
import ModifyFileDialog from "./ModifyFileDialog";
import 'react-toastify/dist/ReactToastify.css';
import TourInfoItemAddOn from "./TourInfoItemAddOn";
import { UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import "./TourInfoItemStyle.css";
import "../../fonts/Font.css"
import { Tooltip } from "react-tooltip";
import images from '../../assets/images';
import "../styles/CommonStyle.css";

export default function TourInfoItemV2(Props) {
  const navigate = useNavigate();

  // 함수
  function setColor(country) {
    switch (country) {
      case "인도네시아":
        return TOURINFO_COLOR_SET_IN.IN;
      case "필리핀":
        return TOURINFO_COLOR_SET_IN.PH;
      default:
        return TOURINFO_COLOR_SET_IN.CANCEL;
    }
  }

  function checkExistTour(startDate, endDate) {
    if (today >= new Date(startDate.replace(" ", "T")) && today <= new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function checkCompleteTour(endDate) {
    if (today > new Date(endDate.replace(" ", "T")))
      return true;
    else
      return false;
  }

  function getMinusDday(startDate) {
    var dayCount = (new Date(startDate.replace(" ", "T")) - today) / 86400000;

    return dayCount;
  }

  function getPlusDday(startDate) {
    var dayCount = (today - new Date(startDate.replace(" ", "T"))) / 86400000 + 1;

    return dayCount;
  }

  function getTourDay(startDate, endDate) {
    var dayCount = (new Date(endDate.replace(" ", "T")) - new Date(startDate.replace(" ", "T"))) / 86400000 + 1;

    return (dayCount - 1) + "박 " + (dayCount) + "일";
  }

  const userInfo = useRecoilValue(UserInfo);

  // 변수
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // 전달 받은 정보
  let tourInfo = Props.data;
  let tourGuideInfo = Props.guide;
  let tourHotelInfo = Props.hotel;
  let toursHotelLogInfo = Props.hotelLog;
  let tourBusInfo = Props.bus;
  let tourCheckListInfo = Props.checkList;
  let toursFunds = Props.funds;
  let toursFiles = Props.files;

  let hotelList = Props.hotelist;
  let busList = Props.buslist;

  let guideMemoList = Props.guideMemoList;

  // 가공 정보
  let hotelTmpArray = tourHotelInfo?.filter(hotelInfo => hotelInfo.tourId === tourInfo.id).map(item => { return item.hotelId });
  let uniqueHotelList = [...new Set(hotelTmpArray)];
  let filterToursHotel = tourHotelInfo?.filter(hotelInfo => hotelInfo.tourId === tourInfo.id);

  let busTmpArray = tourBusInfo?.filter(busInfo => busInfo.tourId === tourInfo.id).map(item => { return item.busId });
  let uniqueBusList = [...new Set(busTmpArray)];
  let filterToursBus = tourBusInfo?.filter(busInfo => busInfo.tourId === tourInfo.id);

  // 색상정보
  let colorSet = tourInfo.cancel === 1 ? setColor("cancel") : setColor(tourInfo.name);

  // 상태 정보 변경 위한 변수
  let reservationState = 0;

  function setReservationState(value) {
    reservationState = value;
  }

  // 부가 정보 표시
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  // 예약창 팝업
  const [showReservationHotelDialog, setShowReservationHotelDialog] = useState(false);
  const [showReservationBusDialog, setShowReservationBusDialog] = useState(false);
  const [showReservationGuideDialog, setShowReservationGuideDialog] = useState(false);

  // 텍스트 자료 수정 팝업
  const [showModifyDialog, setShowModifyDialog] = useState(false);

  // 파일 수정 팝업
  const [showModifyFileDialog, setShowFileModifyDialog] = useState(false);

  let dday = checkExistTour(tourInfo.startDate, tourInfo.endDate) ? getPlusDday(tourInfo.startDate) + "일차" : (checkCompleteTour(tourInfo.endDate) ? "완료" : "D-" + getMinusDday(tourInfo.startDate));

  // 입국인원 입력 팝업
  const [showEnterPaxDialog, setShowEnterPaxDialog] = useState(false);
  const [enterPax, setEnterPax] = useState("");

  // 프리데이 입력
  const [showFreeDayDialog, setShowFreeDayDialog] = useState(false);
  const [freeDay, setFreeDay] = useState(null);

  async function modifyEnterPax() {
    if (enterPax.length === 0) return;

    let res = await HTTPManager.ModifyTourEnterPaxInfo({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.id,
      param: {
        enterPax: enterPax,
      },
    });

    if (res?.data?.result) {
      setEnterPax("");
      setShowEnterPaxDialog(false);
      Props.refresh();
    } else {
      alert("수정 실패!");
    }
  }

  async function modifyFreeDay() {
    if (freeDay.length === 0 || freeDay === null) return;

    let res = await HTTPManager.ModifyTourFreeDayInfo({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.id,
      param: {
        freeDay: freeDay,
      },
    });

    if (res?.data?.result) {
      setFreeDay("");
      setShowFreeDayDialog(false);
      Props.refresh();
    } else {
      alert("수정 실패!");
    }
  }

  async function handleDownloadFile(fileURL, type) {
    let splitList = fileURL.split('/');
    let fileName = splitList[splitList.length - 1];
    let fileExt = fileName.split('.')[1];
    fileName = `[${tourInfo.id}] ${type}.${fileExt}`

    let res = await HTTPManager.GetOriginalFileName({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.id,
      type: type,
    });

    if (res.data !== undefined && res.data.result !== null) {
      fileName = res.data.result
    }

    fetch(fileURL, {
      method: 'GET',
      headers: {
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );

        document.body.appendChild(link);

        var answer = window.confirm(`Save ${fileName}?`);
        if (answer) {
          link.click();
          link.parentNode.removeChild(link);
        }
      });
  }

  function getToursHotelChangeType(value) {
    // 1 : 룸타입  2 : 체크인  4 : 체크아웃 8 : 삭제
    let res = []

    if ((1 & value) !== 0) {
      res.push("RoomType");
    }

    if ((2 & value) !== 0) {
      res.push("Check In");
    }

    if ((4 & value) !== 0) {
      res.push("Check Out");
    }

    if ((8 & value) !== 0) {
      res.push("삭제");
    }

    return res.toString();
  }

  function getFundUI(city, state) {
    let res = [];

    switch (city) {
      // 서울
      case 0:
        break;
      // 강원
      case 1:
        res.push(<img src={images.GANGWON} className="ml-2" key="GANGWON" />);
        break;
    }

    switch (state) {
      // 신청완료
      case 0:
        res.push(<div key={state} className={`state0 h-5 w-5 bg-red-500 rounded-full text-[white] text-[13px] font-bold cursor-pointer select-none`}><Tooltip anchorSelect={`.state0`} place="top">신청완료</Tooltip> </div>);
        break;
      // 지원금확정
      case 1:
        res.push(<div key={state} className={`state1 h-5 w-5 bg-yellow-400 rounded-full text-[white] text-[13px] font-bold cursor-pointer select-none`}><Tooltip anchorSelect={`.state1`} place="top">지원금확정</Tooltip> </div>);
        break;
      // 결과보고완료
      case 2:
        res.push(<div key={state} className={`state2 h-5 w-5 bg-green-600  rounded-full text-[white] text-[13px] font-bold cursor-pointer select-none`}><Tooltip anchorSelect={`.state2`} place="top">결과보고완료</Tooltip> </div>);
        break;
    }

    return res;
  }

  function getAirplaneLOGO(flight) {
    let res = [];

    flight = flight?.toUpperCase();

    let style = "mx-2 rounded-lg bg-[#eee]";

    if (flight?.includes("KE")) {
      res.push(<img src={images.KOREANAIR} className={`${style}`} key="KE" />);
    } else if (flight?.includes("OZ")) {
      res.push(<img src={images.ASIANA} className={`${style}`} key="OZ" />);
    } else if (flight?.includes("SQ")) {
      res.push(<img src={images.SIN} className={`${style}`} key="SQ" />);
    } else if (flight?.includes("PR")) {
      res.push(<img src={images.PAL} className={`${style}`} key="PR" />);
    } else if (flight?.includes("GA")) {
      res.push(<img src={images.GA} className={`${style}`} key="GA" />);
    } else if (flight?.includes("Z2")) {
      res.push(<img src={images.AIRASIA} className={`${style}`} key="Z2" />);
    } else if (flight?.includes("D7")) {
      res.push(<img src={images.AIRASIAX} className={`${style}`} key="D7" />);
    } else if (flight?.includes("5J")) {
      res.push(<img src={images.CEBU} className={`${style}`} key="5J" />);
    } else if (flight?.includes("CX")) {
      res.push(<img src={images.CPA} className={`${style}`} key="CX" />);
    } else if (flight?.includes("VN")) {
      res.push(<img src={images.VN} className={`${style}`} key="VN" />);
    } else if (flight?.includes("MH")) {
      res.push(<img src={images.MH} className={`${style}`} key="MH" />);
    } else if (flight?.includes("BI")) {
      res.push(<img src={images.BI} className={`${style}`} key="BI" />);
    } else if (flight?.includes("OD")) {
      res.push(<img src={images.OD} className={`${style}`} key="OD" />);
    } else if (flight?.includes("TR")) {
      res.push(<img src={images.TR} className={`${style}`} key="TR" />);
    } else if (flight?.includes("TG")) {
      res.push(<img src={images.TG} className={`${style}`} key="TG" />);
    } else if (flight?.includes("VJ")) {
      res.push(<img src={images.VJ} className={`${style}`} key="VJ" />);
    } else if (flight?.includes("7C")) {
      res.push(<img src={images.JEJUAIR} className={`${style}`} key="7C" />);
    } else if (flight?.includes("MU")) {
      res.push(<img src={images.MU} className={`${style}`} key="MU" />);
    } else if (flight?.includes("NX")) {
      res.push(<img src={images.NX} className={`${style}`} key="NX" />);
    } else if (flight?.includes("CZ")) {
      res.push(<img src={images.CZ} className={`${style}`} key="CZ" />);
    }

    return res;
  }

  function getOriginalFileName(type) {
    let fileInfoList = toursFiles?.filter(item => item.infoType === type);
    fileInfoList = fileInfoList = fileInfoList.sort((a, b) => {
      if (new Date(a.createdAt.replace(" ", "T")) > new Date(b.createdAt.replace(" ", "T")))
        return -1;
      if (new Date(a.createdAt.replace(" ", "T")) < new Date(b.createdAt.replace(" ", "T")))
        return 1;
    });

    return fileInfoList[0]?.originalName;
  }

  const clearEnterPax = tourInfo.enterPax !== null && tourInfo.enterPax !== undefined;

  const [showGuideMemo, setShowGuideMemo] = useState(false);
  const [guideMemo, setGuideMemo] = useState("");

  async function modifyIsBigInfo() {
    let res = await HTTPManager.ModifyTourIsBigInfo({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.id,
      param: {
        isBig: (tourInfo.isBig === undefined || tourInfo.isBig === null) ? "on" : "off",
      },
    });

    if (res?.data?.result) {
      Props.refresh();
    } else {
      alert("수정 실패!");
    }
  }

  async function modifyFreeDayInfo(freeDay) {
    let res = await HTTPManager.ModifyTourFreeDayInfo({
      accessToken: userInfo.accessToken,
      tourId: tourInfo.id,
      param: {
        freeDay: freeDay,
      },
    });

    if (res?.data?.result) {
      Props.refresh();
    } else {
      alert("수정 실패!");
    }
  }

  function getTourDayForFreeDay(startDate, endDate) {
    let dayCount = (new Date(endDate.replace(" ", "T")) - new Date(startDate.replace(" ", "T"))) / 86400000 + 1;
    let startDay = new Date(startDate.replace(" ", "T"));
    let result = [];
    let shiftValue = 1;

    for (let i = 0; i < dayCount; i++) {
      startDay.setDate(startDay.getDate() + 1);

      result.push(
        <div key={startDay.toISOString()}
          className="px-2 py-1 bg-[crimson] rounded-full text-white cursor-pointer select-none"
          style={{
            background: ((shiftValue) & tourInfo.freeDay) > 0 ? "crimson" : "#cfcfcf"
          }}
          onClick={async () => {
            await modifyFreeDayInfo(i + 1);
          }}>
          {startDay.toISOString().slice(8, 10)}
        </div>)

      shiftValue <<= 1;
    }

    return result;
  }

  return (
    <div className="flex flex-col w-full h-full px-2 py-1 md:p-0 normal-case md:mb-[5px]" style={{
      //fontFamily: "Noto Sans KR"      
    }}>
      <div className={`border-[1px] border-[black] border-b-[3px] border-r-[3px] rounded-lg ${tourInfo.cancel === 1 ? "bg-[#ff000077]" : ""}`}>
        <div className="scrollRemoveBox flex flex-row gap-2 font-bold bg-[#efefef] text-[black] items-center px-2 rounded-t-lg text-[14px]">
          <div className="flex-none hover:bg-[black] hover:text-[white] hover:cursor-pointer select-none p-1" onClick={() => setShowModifyDialog(true)}>수정</div>
          <div className="flex-none hover:bg-[black] hover:text-[white] hover:cursor-pointer select-none p-1" onClick={async () => {
            var answer = window.confirm(`${tourInfo.id}번을 복사하시겠습니까?`);
            if (answer) {
              let res = await HTTPManager.CopyTour({
                accessToken: userInfo.accessToken,
                tourId: tourInfo.id,
              })

              if (res?.data !== undefined && res.data.result) {
                Props.refresh();
              }
              else {
                alert("복사 실패!");
              }
            }
          }}>복사</div>
          <div className="flex-none hover:bg-[black] hover:text-[white] hover:cursor-pointer select-none p-1" onClick={async () => {
            let infoStr = tourInfo.cancel === 0 ? "취소" : "재개";
            let answer = window.confirm(`${tourInfo.id}번을 ${infoStr}하시겠습니까?`);
            if (answer) {
              let res = await HTTPManager.UpdateTourState({
                accessToken: userInfo.accessToken,
                tourId: tourInfo.id,
                cancel: tourInfo.cancel,
              })

              if (res?.data !== undefined && res.data.result) {
                Props.refresh();
              }
              else {
                alert("실패!");
              }
            }
          }}>{tourInfo.cancel === 0 ? "취소" : "재개"}</div>
          <div className="flex-none hover:bg-[black] hover:text-[white] hover:cursor-pointer select-none p-1" onClick={() => setShowFileModifyDialog(true)}>업로드</div>
          <div className="flex-none hover:bg-[black] hover:text-[white] hover:cursor-pointer select-none p-1" onClick={() => setShowGuideMemo(true)}>가이드메모</div>
          <div className="flex-none hover:bg-[black] hover:text-[white] hover:cursor-pointer select-none p-1" onClick={() => setShowEnterPaxDialog(true)}>입국인원</div>
          <div className="flex-none hover:bg-[black] hover:text-[white] hover:cursor-pointer select-none p-1" onClick={async () => {
            let answer = window.confirm(`${tourInfo.id}번을 삭제하시겠습니까?`);
            if (answer) {
              let res = await HTTPManager.DeleteTour({
                accessToken: userInfo.accessToken,
                tourId: tourInfo.id,
              })

              if (res?.data !== undefined && res.data.result) {
                Props.refresh();
              }
              else {
                alert("삭제 실패!");
              }
            }
          }}>삭제</div>
        </div>
        {
          tourInfo.docNum &&
          <div className="flex normal-case p-2 text-start font-bold">
            문서번호 {tourInfo.docNum}
          </div>
        }
        <div className={`flex flex-row md:flex-col`}>
          <div className="flex flex-col w-1/2 md:w-full p-2 justify-start">
            <div className={`flex flex-row gap-1 w-full mr-2 font-bold`} style={{ color: colorSet.name }}>
              <div>[{tourInfo.id}] </div>
              <div>{tourInfo.name} </div>
              <div className="flex text-[white] font-bold bg-[black] px-2 py-[2px] rounded-lg md:text-[12px]">{tourInfo.startDate.slice(5, 10).replace("-", ".")}~{tourInfo.endDate.slice(5, 10).replace("-", ".")}</div>
              <div className="flex text-[white] font-bold bg-[black] px-2 py-[2px] rounded-lg md:text-[12px]">{dday} ({getTourDay(tourInfo.startDate, tourInfo.endDate)})</div>
            </div>
            <div className="flex flex-row text-[15px] mt-[10px]">
              {
                (tourInfo.eta !== null) &&
                <div className="flex w-1/2 md:mr-2 flex-row md:flex-col gap-1 cursor-pointer items-center normal-case text-start text-[skyblue] font-bold">
                  <div>입국 {tourInfo.eta}</div>
                  {getAirplaneLOGO(tourInfo.eta)}
                </div>
              }
              {
                (tourInfo.etd !== null) &&
                <div className="flex w-1/2 flex-row md:flex-col gap-1 cursor-pointer items-center normal-case text-start text-[skyblue] font-bold">
                  <div>출국 {tourInfo.etd}</div>
                  {getAirplaneLOGO(tourInfo.etd)}
                </div>
              }
            </div>
            <div className="flex flex-row justify-between text-[15px] mt-[10px] font-bold">
              <div className={`flex w-1/2 text-[black] ${clearEnterPax && "line-through"} md:mr-2`}>인원 {tourInfo.pax}</div>
              {
                clearEnterPax && <div className="flex w-1/2 text-[black] font-bold">실제입국 {tourInfo.enterPax}</div>
              }
            </div>
          </div>
          <div className="flex w-[1px] bg-[#ccc] my-2 mr-1" />
          <div className="flex flex-col w-1/2 md:w-full p-2 justify-start">
            <div className="flex flex-row h-fit gap-1 flex-wrap mb-1">
              <div className="flex w-fit h-fit flex-row font gap-1">
                {
                  // 정산 정보
                  tourGuideInfo.map(guideInfo => {
                    if (guideInfo.accountFinish !== undefined && guideInfo.accountFinish !== null) {
                      return (
                        <div
                          key={guideInfo.id}
                          className="flex text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold bg-green-400"
                          onClick={() => {
                          }}>
                          정산완료
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                }
                {
                  // 팁 지급 정보
                  tourGuideInfo.map(guideInfo => {
                    if (guideInfo.tipURL !== undefined && guideInfo.tipURL !== null) {
                      return (
                        <div
                          key={guideInfo.id}
                          title={guideInfo.tipURL}
                          className="flex text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold bg-green-400"
                          onClick={() => {
                            Utils.showContent(guideInfo.tipURL);
                          }}>
                          팁지급내역
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                }
                {
                  tourGuideInfo.length > 0 ? tourGuideInfo.map(guide => {
                    return (
                      <div key={guide.id} className="flex h-fit bg-[#7D59EA] text-[white] text-[14px] md:text-[12px] font-bold rounded p-1 px-2 select-none cursor-pointer"
                        onClick={() => {
                          setShowReservationGuideDialog(!showReservationGuideDialog);
                        }}>
                        <a className={`guideInfo${guide.id}`}>
                          {guide.name} / {guide.nameEN} / {guide.phoneNumber !== undefined && guide.phoneNumber !== null && guide.phoneNumber}
                        </a>
                        <Tooltip anchorSelect={`.guideInfo${guide.id}`} place="top">
                          생년월일 : {guide.birthDate}
                        </Tooltip>
                      </div>
                    )
                  }) :
                    <div className="flex mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer"
                      onClick={() => {
                        setShowReservationGuideDialog(!showReservationBusDialog);
                      }} >
                      {"가이드(클릭)"}
                    </div>
                }
              </div>
              {
                tourInfo.name === "필리핀" &&
                <div
                  title={tourInfo.payment}
                  className="flex text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold"
                  style={{ background: (tourInfo.payment !== undefined && tourInfo.payment !== null) ? "#7D59EA" : "#7D59EA77" }}>
                  페이먼트
                </div>
              }
              <div
                className="flex w-fit h-fit text-[white] items-center justify-center rounded cursor-pointer text-[14px] md:text-[12px] select-none p-1 px-2 font-bold"
                onClick={() => {
                  setShowModifyDialog(true);
                }}
                style={{
                  background: (tourInfo.dutyFreeShop !== undefined && tourInfo.dutyFreeShop !== null) ? "#333" : "crimson"
                }}>
                {(tourInfo.dutyFreeShop !== undefined && tourInfo.dutyFreeShop !== null) ? tourInfo.dutyFreeShop : "면세점등록필요"}
              </div>
              {
                (tourInfo.freeDay !== null && tourInfo.freeDay !== undefined && tourInfo.freeDay !== 0) &&
                <div
                  className="flex text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold"
                  style={{ background: "crimson" }}>
                  자유일정
                </div>
              }
            </div>
            {
              guideMemoList?.filter(item => item.type === 0)?.length > 0 &&
              <div className="flex w-full flex-col items-start bg-[#F5F6F8] rounded p-3 font-bold text-[15px] mb-1 text-[#31415F]">
                <div>가이드메모({guideMemoList?.filter(item => item.type === 0)?.length})</div>
                <div className="flex w-full h-[1px] bg-[#9EB0BD] my-[10px]" />
                <div className="flex flex-col">
                  {
                    guideMemoList?.filter(item => item.type === 0).map((memo, index) => {
                      return (
                        <div key={memo.id} className="text-start cursor-pointer" onClick={async () => {
                          let answer = window.confirm(
                            `${memo.contents}를 삭제 하시겠습니까?`
                          );

                          if (answer) {
                            let res = await HTTPManager.RemoveGuideMemo({
                              accessToken: userInfo.accessToken,
                              guideMemoId: memo.id,
                            });

                            if (res.data !== undefined && res.data !== null) {
                              if (res.data?.result === true)
                                Props.refresh();
                            }
                          }
                        }}>
                          {index + 1}. {memo.contents}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            }
            {
              (tourInfo.memo !== undefined && tourInfo.memo !== null) &&
              <div className="flex flex-col w-full rounded-lg bg-[#FAE100] p-3 text-left text-[#3C1E1E] font-bold text-[16px] cursor-pointer normal-case"
                onClick={() => {
                  setShowModifyDialog(true);
                }} >
                <div className="">메모</div>
                <div className="flex w-full h-[1px] bg-[#3C1E1E] my-[10px]" />
                <div className="text-start">
                  {tourInfo.memo}
                </div>
              </div>
            }
          </div>
        </div>

        <div className="flex h-[1px] bg-[#ccc] mx-2 my-1" />

        <div className="flex flex-row md:flex-col">
          <div className="flex flex-col w-1/2 md:w-full p-2 justify-start">
            <div className="flex flex-col mb-2 bg-[#F5F6F8] p-3 rounded-lg">
              {
                uniqueHotelList.length > 0 ?
                  uniqueHotelList.map((item) => {
                    return (
                      <div key={item} className="flex flex-col text-[20px] md:text-[15px] md:text-start">
                        {
                          // 필터링된 호텔 예약 정보 한번 더 호텔 id로 필터링
                          // 호텔명, 일정 한번 만 출력하기 위해서
                          filterToursHotel.filter(resHotel => resHotel.hotelId === item).map((resHotel, idx, row) => {
                            return (
                              <div key={resHotel.id} className={`hotelInfo${resHotel.id} flex flex-col mb-2 cursor-pointer`}>
                                <Tooltip anchorSelect={`.hotelInfo${resHotel.id}`} place="top" className="z-[100]">
                                  {
                                    toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).length > 0 ? <div className="flex flex-col font-bold text-[15px] items-start p-3 mt-1 md:mr-0 rounded text-[white]">
                                      {
                                        "수정내역(" + toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).length + ")"
                                      }
                                      {
                                        toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).length > 0
                                        && toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).map((logInfo, idx) => {
                                          return (
                                            <div key={"hotelLog" + logInfo.id}>
                                              [{idx + 1}][{logInfo.modifyAt}][{getToursHotelChangeType(logInfo.changeType)}] ({logInfo.startDate?.slice(8, 10)}-{logInfo.endDate?.slice(8, 10)}) {logInfo.roomType}
                                            </div>
                                          );
                                        })
                                      }
                                    </div> : null
                                  }
                                </Tooltip>
                                <div className="flex flex-col md:flex-col">
                                  <div className="flex flex-row w-full md:flex-col gap-2 mb-1">
                                    <div className="flex text-[crimson] font-bold">
                                      {
                                        "(" + toursHotelLogInfo.filter(logInfo => logInfo.toursHotelId === resHotel.id).length + ")"
                                      }
                                    </div>
                                    <div className="flex flex-row w-full items-center cursor-pointer text-[#fff] bg-[#000c] px-2 rounded"
                                      onClick={() => {
                                        setShowReservationHotelDialog(!showReservationHotelDialog);
                                      }}
                                    >
                                      <div className="flex flex-row font-bold mr-[5px]">
                                        <div className="mr-1">
                                          {hotelList.find(hotel => hotel.id === resHotel.hotelId)?.name}
                                          [{hotelList.find(hotel => hotel.id === resHotel.hotelId)?.address2}]
                                        </div>
                                        <div className="">
                                          ({resHotel.startDate?.slice(8, 10)}-{resHotel.endDate?.slice(8, 10)})
                                        </div>
                                      </div>
                                      <div className="font-medium">
                                        {resHotel.roomType}
                                      </div>
                                    </div>
                                    <div className="md:mb-[2px] font-bold">
                                      <Dropdown
                                        title="상태"
                                        data={StateList.reservationStateList}
                                        setFunc={setReservationState}
                                        onClick={async () => {
                                          let res = await HTTPManager.ChangeStateToursHotel({
                                            tourId: tourInfo.id,
                                            hotelId: resHotel.hotelId,
                                            type: "state",
                                            state: reservationState,
                                          });

                                          if (res?.data) {
                                            //await fetchData();
                                          }
                                          else {
                                            alert("변경 실패!");
                                          }
                                        }}
                                        padding={"0px 10px 0px 10px"}
                                        initId={resHotel.state}
                                      />
                                    </div>
                                  </div>
                                  {
                                    (resHotel.createdAt !== null || resHotel.modifyAt !== null) &&
                                    <div className="flex flex-row gap-1 my-[2px] justify-end">
                                      {
                                        resHotel.createdAt !== null &&
                                        <div className="flex flex-row text-left text-[16px] cursor-pointer">
                                          <div className="flex bg-gradient-to-r from-red-500 to-orange-500 rounded-lg items-center">
                                            <span className={"cursor-pointer px-1 py-1"} />
                                            <div className="text-white flex flex-row items-center mr-2 p-1">
                                              <div className="text-[13px] font-bold mr-0.5">생성</div>
                                              <div className="text-[13px] font-bold">({resHotel.createdAt.slice(2, 20)})</div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                      {
                                        resHotel.modifyAt !== null &&
                                        <div className="flex flex-row text-left text-[16px] cursor-pointer animate-pulse">
                                          <div className="flex bg-gradient-to-r from-green-500 to-lime-500 rounded-lg items-center">
                                            <span className={"cursor-pointer px-1 py-1"} />
                                            <div className="text-white flex flex-row items-center mr-2  p-1">
                                              <div className="text-[13px] font-bold mr-0.5">변경</div>
                                              <div className="text-[13px] font-bold">({resHotel.modifyAt.slice(2, 20)})</div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  }) :
                  <div className="mr-[5px] text-[crimson] font-bold text-[16px] text-start cursor-pointer"
                    onClick={() => {
                      setShowReservationHotelDialog(!showReservationHotelDialog);
                    }}
                  >
                    {"호텔예약(클릭)"}
                  </div>
              }
            </div>
            <div className="flex flex-col bg-[#F5F6F8] p-3 rounded-lg">
              {
                uniqueBusList.length > 0 ? uniqueBusList.map((item) => {
                  return (
                    filterToursBus.filter(toursBus => toursBus.busId === item).map((toursBus, idx, row) => {
                      let busInfo = busList.find(bus => bus.id === toursBus.busId);
                      let busName = busInfo?.name;
                      let busDriver = busName === "글로벌컴퍼니" ? " " + busInfo.manager : "";

                      return (
                        <div key={toursBus.id} className="flex flex-col w-full md:flex-col cursor-pointer text-[20px] md:text-[15px]">
                          <div className="flex flex-row items-center">
                            <div className="flex flex-row items-center cursor-pointer text-[#fff] bg-[#000c] px-2 rounded mr-2"
                              onClick={() => {
                                setShowReservationBusDialog(!showReservationBusDialog);
                              }}
                            >
                              <div className="mr-[5px] font-bold">
                                {busList.find(bus => bus.id === toursBus.busId)?.name + busDriver}
                              </div>
                              <div className="font-medium">
                                {toursBus.busType}
                              </div>
                            </div>
                            {
                              idx === row.length - 1 &&
                              <div className="font-bold">
                                <Dropdown
                                  title="상태"
                                  data={StateList.reservationStateList}
                                  setFunc={setReservationState}
                                  onClick={async () => {
                                    let res = await HTTPManager.ChangeStateToursBus({
                                      tourId: tourInfo.id,
                                      busId: toursBus.busId,
                                      type: "state",
                                      state: reservationState,
                                    });

                                    if (res?.data) {
                                      //await fetchData();
                                    }
                                    else {
                                      alert("변경 실패!");
                                    }
                                  }}
                                  padding={"0px 10px 0px 10px"}
                                  initId={toursBus.state}
                                />
                              </div>
                            }
                          </div>
                        </div>
                      )
                    })
                  )
                }) :
                  <div className="flex mr-[5px] text-[crimson] font-bold text-[16px] cursor-pointer"
                    onClick={() => {
                      setShowReservationBusDialog(!showReservationBusDialog);
                    }} >
                    {"버스예약(클릭)"}
                  </div>
              }
            </div>
            {
              <div className="flex w-full flex-row justify-center mt-1">
                <div
                  className="flex w-full  text-[white] items-center justify-center rounded cursor-pointer text-[14px] select-none p-1 px-2 font-bold"
                  style={{
                    background: (tourInfo?.isBig === null || tourInfo?.isBig === undefined) ? "#cfcfcf" : "#4ade80"
                  }}
                  onClick={async () => {
                    await modifyIsBigInfo();
                  }}>
                  대형
                </div>
              </div>
            }
            <div className="flex flex-row w-full text-left mt-1 font-bold gap-1 flex-wrap">
              {
                // bit 단위                    
                getTourDayForFreeDay(tourInfo.startDate, tourInfo.endDate)
              }
            </div>

          </div>
          <div className="flex w-[1px] bg-[#ccc] my-2 mr-1 md:my-0" />
          {
            tourInfo.name === "인도네시아" && <div className="flex flex-col w-1/2 md:w-full p-1 mr-1 md:mr-0 justify-start ">
              <div className="flex flex-col w-full rounded-lg bg-[#F5F6F8] p-3 mt-1 md:mt-0 text-left text-[#31415F] font-bold text-[16px] cursor-pointer normal-case">
                <div className="mb-[15px]">첨부파일</div>
                <div className="flex h-[1px] bg-[#9EB0BD]" />
                <div className="flex-none text-[#222260] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1 mt-[12px]">
                  <div className="flex flex-row items-center justify-between md:gap-1">
                    {
                      (tourInfo.logo !== undefined && tourInfo.logo !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            handleDownloadFile(tourInfo.logo, "logo");
                          }}>
                            <FontAwesomeIcon icon={faCloudDownload} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                    <div className="items-center w-full justify-start overflow-hidden" onClick={() => {
                      Utils.showContent(tourInfo.logo);
                    }}>[로고] {getOriginalFileName("logo")}</div>
                    {
                      (tourInfo.logo !== undefined && tourInfo.logo !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            e.stopPropagation();

                            let answer = window.confirm(
                              "파일을 삭제 하시겠습니까?"
                            );

                            if (answer) {
                              await HTTPManager.RemoveTourFile({
                                accessToken: userInfo.accessToken,
                                id: tourInfo.id,
                                type: "logo"
                              });
                            }
                            Props.refresh();
                          }}>
                            <FontAwesomeIcon icon={faTrash} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                  </div>
                </div>

                <div className="w-full bg-[#E2E6EC] h-[1px] my-[12px]" />

                <div className="flex-none w-fil text-[#222260] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1">
                  <div className="flex flex-row items-center justify-between md:gap-1">
                    {
                      (tourInfo.rooming !== undefined && tourInfo.rooming !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            handleDownloadFile(tourInfo.rooming, "rooming");
                          }}>
                            <FontAwesomeIcon icon={faCloudDownload} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                    <div className="items-center w-full justify-center overflow-hidden" onClick={() => {
                      Utils.showContent(tourInfo.rooming);
                    }}>[루밍] {getOriginalFileName("rooming")}</div>
                    {
                      (tourInfo.rooming !== undefined && tourInfo.rooming !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            e.stopPropagation();

                            let answer = window.confirm(
                              "파일을 삭제 하시겠습니까?"
                            );

                            if (answer) {
                              await HTTPManager.RemoveTourFile({
                                accessToken: userInfo.accessToken,
                                id: tourInfo.id,
                                type: "rooming"
                              });
                            }
                            Props.refresh();
                          }}>
                            <FontAwesomeIcon icon={faTrash} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                  </div>
                </div>

                <div className="w-full bg-[#E2E6EC] h-[1px] my-[12px]" />

                <div className="flex-none w-fil text-[#222260] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1">
                  <div className="flex flex-row items-center justify-between md:gap-1">
                    {
                      (tourInfo.finalDocIN !== undefined && tourInfo.finalDocIN !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            handleDownloadFile(tourInfo.finalDocIN, "finalDocIN");
                          }}>
                            <FontAwesomeIcon icon={faCloudDownload} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                    <div className="items-center w-full justify-center overflow-hidden" onClick={() => {
                      Utils.showContent(tourInfo.finalDocIN);
                    }}>[파이널(ITS)] {getOriginalFileName("finalDocIN")}</div>
                    {
                      (tourInfo.finalDocIN !== undefined && tourInfo.finalDocIN !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            e.stopPropagation();

                            let answer = window.confirm(
                              "파일을 삭제 하시겠습니까?"
                            );

                            if (answer) {
                              await HTTPManager.RemoveTourFile({
                                accessToken: userInfo.accessToken,
                                id: tourInfo.id,
                                type: "finalDocIN"
                              });
                            }
                            Props.refresh();
                          }}>
                            <FontAwesomeIcon icon={faTrash} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                  </div>
                </div>

                <div className="w-full bg-[#E2E6EC] h-[1px] my-[12px]" />

                <div className="flex-none w-fil text-[crimson] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1">
                  <div className="flex flex-row items-center justify-between md:gap-1">
                    {
                      (tourInfo.finalDoc !== undefined && tourInfo.finalDoc !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            handleDownloadFile(tourInfo.finalDoc, "finalDoc");
                          }}>
                            <FontAwesomeIcon icon={faCloudDownload} className="text-[#222260] hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                    <div className="items-center w-full justify-center overflow-hidden" onClick={() => {
                      Utils.showContent(tourInfo.finalDoc);
                    }}>[파이널(KTS)] {getOriginalFileName("finalDoc")}</div>
                    {
                      (tourInfo.finalDoc !== undefined && tourInfo.finalDoc !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            e.stopPropagation();

                            let answer = window.confirm(
                              "파일을 삭제 하시겠습니까?"
                            );

                            if (answer) {
                              await HTTPManager.RemoveTourFile({
                                accessToken: userInfo.accessToken,
                                id: tourInfo.id,
                                type: "finalDoc"
                              });
                            }
                            Props.refresh();
                          }}>
                            <FontAwesomeIcon icon={faTrash} className="text-[#222260] hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                  </div>
                </div>

                <div className="w-full bg-[#E2E6EC] h-[1px] my-[12px]" />

                <div className="flex-none w-fil text-[#222260] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1">
                  <div className="flex flex-row items-center justify-between md:gap-1">
                    {
                      (tourInfo.invoice !== undefined && tourInfo.invoice !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            handleDownloadFile(tourInfo.invoice, "invoice");
                          }}>
                            <FontAwesomeIcon icon={faCloudDownload} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                    <div className="items-center w-full justify-center overflow-hidden" onClick={() => {
                      Utils.showContent(tourInfo.invoice);
                    }}>[인보이스] {getOriginalFileName("invoice")}</div>
                    {
                      (tourInfo.invoice !== undefined && tourInfo.invoice !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            e.stopPropagation();

                            let answer = window.confirm(
                              "파일을 삭제 하시겠습니까?"
                            );

                            if (answer) {
                              await HTTPManager.RemoveTourFile({
                                accessToken: userInfo.accessToken,
                                id: tourInfo.id,
                                type: "invoice"
                              });
                            }
                            Props.refresh();
                          }}>
                            <FontAwesomeIcon icon={faTrash} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                  </div>
                </div>

                <div className="w-full bg-[#E2E6EC] h-[1px] my-[12px]" />

                <div className="flex-none w-fil text-[#222260] items-center justify-center rounded cursor-pointer text-[14px] select-none font-bold px-2 py-1">
                  <div className="flex flex-row items-center justify-between md:gap-1">
                    {
                      (tourInfo.scheduleKO !== undefined && tourInfo.scheduleKO !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            handleDownloadFile(tourInfo.scheduleKO, "scheduleKO");
                          }}>
                            <FontAwesomeIcon icon={faCloudDownload} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                    <div className="items-center w-full justify-center overflow-hidden" onClick={() => {
                      Utils.showContent(tourInfo.scheduleKO);
                    }}>[행사지시서] {getOriginalFileName("scheduleKO")}</div>
                    {
                      (tourInfo.scheduleKO !== undefined && tourInfo.scheduleKO !== null) ?
                        <div className="rounded-full w-[25px]">
                          <span className={"cursor-pointer"} onClick={async (e) => {
                            e.stopPropagation();

                            let answer = window.confirm(
                              "파일을 삭제 하시겠습니까?"
                            );

                            if (answer) {
                              await HTTPManager.RemoveTourFile({
                                accessToken: userInfo.accessToken,
                                id: tourInfo.id,
                                type: "scheduleKO"
                              });
                            }
                            Props.refresh();
                          }}>
                            <FontAwesomeIcon icon={faTrash} className="hover:text-[crimson]" />
                          </span>
                        </div> :
                        <div></div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>

        <div className="flex h-[1px] bg-[#ccc] mx-2 my-1" />

        <div className="flex flex-row md:flex-col mb-1 md:mb-0 p-2 md:p-1 justify-end md:items-end">
          {
            (tourInfo.createdAt !== undefined && tourInfo.createdAt !== null) &&
            <div className="flex flex-row text-left text-[16px] cursor-pointer mr-1 md:mb-1">
              {/* [#FDD535] [#5BC6CC] [#48A6DC] [#989D9F] [#F06093] [#9580BB]*/}
              <div className="flex bg-[#5BC6CC] rounded-lg items-center">
                <span className={"cursor-pointer px-2 py-1"}>
                  <FontAwesomeIcon icon={faTag} style={{ color: "white" }} />
                </span>
                <div className="text-white flex flex-row items-center mr-2">
                  <div className="text-[13px] font-bold mr-0.5">생성</div>
                  <div className="text-[13px] font-bold">({tourInfo.createdAt.slice(2, 20)})</div>
                </div>
              </div>
            </div>
          }
          {
            (tourInfo.modifyValue !== undefined && tourInfo.modifyValue !== null) &&
            <div className="flex flex-row text-left text-[16px] cursor-pointer mr-1 md:mb-1">
              {/* [#FDD535] [#5BC6CC] [#48A6DC] [#989D9F] [#F06093] [#9580BB]*/}
              <div className="flex bg-[#9580BB] rounded-lg items-center">
                <span className={"cursor-pointer px-2 py-1"}>
                  <FontAwesomeIcon icon={faTag} style={{ color: "white" }} />
                </span>
                <div className="text-white flex flex-row items-center mr-2">
                  <div className="text-[13px] font-bold mr-0.5">{tourInfo.modifyValue}</div>
                  <div className="text-[13px] font-bold">({tourInfo.modifyAt.slice(2, 20)})</div>
                </div>
              </div>
            </div>
          }
          {
            (tourInfo.roomingAt !== undefined && tourInfo.roomingAt !== null) &&
            <div className="flex flex-row text-left text-[16px] cursor-pointer mr-1 md:mb-1">
              {/* [#FDD535] [#5BC6CC] [#48A6DC] [#989D9F] [#F06093] [#9580BB]*/}
              <div className="flex bg-gradient-to-r from-[#1e90ff] to-[#00ced1] rounded-lg items-center">
                <span className={"cursor-pointer px-2 py-1"}>
                  <FontAwesomeIcon icon={faTag} style={{ color: "white" }} />
                </span>
                <div className="text-white flex flex-row items-center mr-2">
                  <div className="text-[13px] font-bold mr-0.5">루밍</div>
                  <div className="text-[13px] font-bold">({tourInfo.roomingAt.slice(2, 20)})</div>
                </div>
              </div>
            </div>
          }
          {
            (tourInfo.roomingModifyAt !== undefined && tourInfo.roomingModifyAt !== null) &&
            <div className="flex flex-row text-left text-[16px] cursor-pointer animate-pulse md:mb-1">
              <div className="flex bg-gradient-to-r from-red-500 to-purple-500 rounded-lg items-center">
                <span className={"cursor-pointer px-2 py-1 animate-spin"}>
                  <FontAwesomeIcon icon={faSpinner} style={{ color: "white" }} />
                </span>
                <div className="text-white flex flex-row items-center mr-2">
                  <div className="text-[13px] font-bold mr-0.5">루밍변경</div>
                  <div className="text-[13px] font-bold">({tourInfo.roomingModifyAt.slice(2, 20)})</div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {
        // 추가 메뉴
        showAdditionalInfo &&
        <TourInfoItemAddOn
          colorSet={colorSet}
          tourInfo={tourInfo}
          tourHotelInfo={tourHotelInfo}
          tourBusInfo={tourBusInfo}
          tourGuideInfo={tourGuideInfo}
          hotelList={hotelList}
          busList={busList}
          setShow={setShowAdditionalInfo}
          refresh={Props.refresh}
        />
      }
      {
        // 호텔 예약
        showReservationHotelDialog &&
        <ReservationHotelDialog
          setShow={setShowReservationHotelDialog}
          tourInfo={tourInfo}
          reservationInfo={tourHotelInfo.filter(item => item.tourId === tourInfo.id)}
          refresh={Props.refresh}
        />
      }
      {
        // 버스 예약
        showReservationBusDialog &&
        <ReservationBusDialog
          setShow={setShowReservationBusDialog}
          tourInfo={tourInfo}
          reservationInfo={tourBusInfo.filter(item => item.tourId === tourInfo.id)}
          refresh={Props.refresh}
        />
      }
      {
        // 가이드 예약
        showReservationGuideDialog &&
        <ReservationGuideDialog
          setShow={setShowReservationGuideDialog}
          tourInfo={tourInfo}
          reservationInfo={tourGuideInfo.filter(item => item.tourId === tourInfo.id)}
          refresh={Props.refresh}
        />
      }
      {
        // 투어 정보 수정
        showModifyDialog &&
        <ModifyDialog
          setShow={setShowModifyDialog}
          tourInfo={tourInfo}
          tourCheckListInfo={tourCheckListInfo.find(item => item.tourId === tourInfo.id)}
          refresh={Props.refresh}
        />
      }
      {
        // 파일업로드
        showModifyFileDialog &&
        <ModifyFileDialog
          setShow={setShowFileModifyDialog}
          tourInfo={tourInfo}
          refresh={Props.refresh}
          guideInfo={tourGuideInfo.filter(item => item.tourId === tourInfo.id)}
        />
      }
      {
        showGuideMemo && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none font-medium">
              <div className="relative w-full my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-center mt-5 rounded-t">
                    <h2 className="text-base">가이드용 메모</h2>
                  </div>
                  <div className="relative p-3 flex-auto">
                    <textarea
                      rows="10"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none"
                      placeholder={"내용입력"}
                      onChange={(e) => {
                        setGuideMemo(e.target.value);
                      }}
                    />
                  </div>

                  <div className="flex w-full items-center justify-center rounded-b border-t">
                    <button
                      className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                      type="button"
                      onClick={() => {
                        setShowGuideMemo(false);
                      }}
                    >
                      취소
                    </button>
                    <button
                      className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                      type="button"
                      onClick={async () => {
                        if (guideMemo.length > 0) {
                          let result = await HTTPManager.InsertGuideMemo({
                            accessToken: userInfo.accessToken,
                            param: {
                              tourId: tourInfo.id,
                              contents: guideMemo,
                              type: 0,
                            }
                          });

                          if (result.data !== undefined && result.data !== null) {
                            if (result.data?.result === true) {
                              alert('메모 등록 완료');
                              setShowGuideMemo(false);
                              Props.refresh();
                            }
                          }
                        }
                      }}
                    >
                      기록
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        )
      }
      {
        showEnterPaxDialog ? (
          <>
            <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none font-medium select-none">
              <div className="relative w-full my-6 mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-center p-5 rounded-t">
                    <h2 className="text-base">입국인원</h2>
                  </div>
                  <div className="relative p-3 flex-auto">
                    <input
                      autoComplete="off"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                      placeholder={"입국인원"}
                      type="text"
                      name="text"
                      onChange={(e) => {
                        setEnterPax(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          modifyEnterPax();
                        }
                      }}
                      value={enterPax}
                    />
                  </div>

                  <div className="flex w-full items-center justify-center rounded-b border-t">
                    <button
                      className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                      type="button"
                      onClick={() => {
                        setEnterPax("");
                        setShowEnterPaxDialog(false);
                      }}
                    >
                      {"취소"}
                    </button>
                    <button
                      className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                      type="button"
                      onClick={async () => {
                        modifyEnterPax();
                      }}
                    >
                      {"입력"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        ) : null
      }
      {
        showFreeDayDialog ? (
          <>
            <div className="mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none font-medium select-none">
              <div className="relative w-full my-6 mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-center p-5 rounded-t">
                    <h2 className="text-base">프리데이</h2>
                  </div>
                  <div className="relative p-3 flex-auto">
                    <input
                      autoComplete="off"
                      className="w-full placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      placeholder={"프리데이"}
                      type="number"
                      name="text"
                      onChange={(e) => {
                        let dayCount = (new Date(tourInfo.endDate.replace(" ", "T")) - new Date(tourInfo.startDate.replace(" ", "T"))) / 86400000 + 1;
                        if (e.target.value > dayCount) {
                          alert("투어 기간보다 작은 값을 입력해주세요");
                          setFreeDay(String(dayCount));
                        } else {
                          setFreeDay(e.target.value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (["e", "E", "+", "-", "ArrowDown", "ArrowUp"].includes(e.key)) {
                          e.preventDefault();
                        }

                        if (e.key === 'Enter') {
                          modifyFreeDay();
                        }
                      }}
                      value={freeDay}
                    />
                  </div>

                  <div className="flex w-full items-center justify-center rounded-b border-t">
                    <button
                      className="flex w-full justify-center py-3 rounded-bl-lg focus:outline-none"
                      type="button"
                      onClick={() => {
                        setFreeDay(null);
                        setShowFreeDayDialog(false);
                      }}
                    >
                      {"취소"}
                    </button>
                    <button
                      className="flex w-full bg-[black] text-[white] justify-center py-3 rounded-br-lg focus:outline-none"
                      type="button"
                      onClick={async () => {
                        modifyFreeDay();
                      }}
                    >
                      {"입력"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        ) : null
      }
    </div>
  )
}
