import React, { useState, } from "react";
import * as Utils from '../../features/Utils';
import { LanguageSet, ShowInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import UploadDialog from "./UploadDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function RoomingInfoItem(Props) {
  const languageSet = useRecoilValue(LanguageSet);
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  const tourInfo = Props.data;

  const docNum = tourInfo.docNum;
  const rooming = tourInfo.rooming;
  const finalDoc = tourInfo.finalDoc;
  const finalDocIN = tourInfo.finalDocIN;
  const invoice = tourInfo.invoice;
  const logo = tourInfo.logo;

  const linkOnBackground = "#4171D6";
  const linkOffBackground = "#B3CDFF";

  //const userInfo = useRecoilValue(UserInfo);
  const showInfo = useRecoilValue(ShowInfo);

  const [uploadType, setUploadType] = useState("");

  const reservationHotel = Props.toursHotelInfo.filter(item => item.tourId === tourInfo.id);
  const reservationGuide = Props.toursGuideInfo.filter(item => item.tourId === tourInfo.id);
  const userList = Props.userList;
  const hotelList = Props.hotelList;

  return (
    <div className="flex flex-col border border-slate-300 mb-1 p-2 rounded">
      <div className="flex flex-row justify-between mb-2">
        <div className="relative flex flex-row md:flex-col w-full font-bold">
          {
            showInfo && <div className="absolute top-[0px] left-0 text-[white] z-[101]">Operation Room Document Name // Guide Name</div>
          }
          <div className="mr-1">
            {docNum} (PAX. {tourInfo.pax})
          </div>
          {
            reservationGuide.length > 0 ?
              <div className="relative text-[crimson] normal-case">
                {
                  reservationGuide.map(item => {
                    return userList.find(user => user.id === item.guideId)?.nameEN;
                  }).toString()
                }
              </div> :
              <div className="text-[crimson]">TBA</div>
          }
        </div>
        <div className="font-bold">[{tourInfo.id}]</div>
      </div>
      <div className="flex flex-row justify-between mb-1">
        <div className="relative flex flex-col">
          {
            reservationHotel.length > 0 &&
            <div>
              {
                reservationHotel.map(item => {
                  return (
                    <div key={item.id}>
                      {hotelList.find(hotel => hotel.id === item.hotelId)?.nameEN} {item.startDate.slice(2, 10)}~{item.endDate.slice(2, 10)}
                    </div>
                  )
                })
              }
            </div>
          }
          {
            showInfo && <div className="absolute top-[0px] left-0 text-[white] font-bold z-[101]">Reservation Hotel</div>
          }
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row justify-end mb-1">
            <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold mr-2"
              style={{ background: (rooming !== undefined && rooming !== null) ? linkOnBackground : linkOffBackground }}
              onClick={() => {
                if (rooming !== undefined && rooming !== null)
                  Utils.showContent(rooming);
              }}>
              {languageSet.roomingItemButton}
            </div>
            <span
              title="Upload"
              className={"cursor-pointer px-2 py-1 rounded mr-1"}
              style={{ background: "#34414d" }}
              onClick={() => {
                setShowUploadDialog(true);
                setUploadType("rooming");
              }}>
              <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
            </span>
          </div>

          <div className="flex flex-row justify-end mb-1">
            <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold mr-2"
              style={{ background: (logo !== undefined && logo !== null) ? linkOnBackground : linkOffBackground }}
              onClick={() => {
                if (logo !== undefined && logo !== null)
                  Utils.showContent(logo);
              }}>
              {languageSet.roomingItemLogoButton}
            </div>
            <span
              title="Upload"
              className={"cursor-pointer px-2 py-1 rounded mr-1"}
              style={{ background: "#34414d" }}
              onClick={() => {
                setShowUploadDialog(true);
                setUploadType("logo");
              }}>
              <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
            </span>
          </div>

          <div className="flex flex-row justify-end mb-1">
            <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold mr-2"
              style={{ background: (finalDocIN !== undefined && finalDocIN !== null) ? linkOnBackground : linkOffBackground }}
              onClick={() => {
                if (finalDocIN !== undefined && finalDocIN !== null)
                  Utils.showContent(finalDocIN);
              }}>
              {languageSet.roomingItemfinalDocumentITSButton}
            </div>
            <span
              title="Upload"
              className={"cursor-pointer px-2 py-1 rounded mr-1"}
              style={{ background: "#34414d" }}
              onClick={() => {
                setShowUploadDialog(true);
                setUploadType("finalDocIN");
              }}>
              <FontAwesomeIcon icon={faCloudArrowUp} style={{ color: "white" }} />
            </span>
          </div>

          <div className="flex flex-row justify-end mb-1">
            <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold"
              style={{ background: (finalDoc !== undefined && finalDoc !== null) ? linkOnBackground : linkOffBackground }}
              onClick={() => {
                if (finalDoc !== undefined && finalDoc !== null)
                  Utils.showContent(finalDoc);
              }}>
              {languageSet.roomingItemfinalDocumentButton}
            </div>
          </div>

          <div className="flex flex-row justify-end">
            <div className="flex text-[white] items-center justify-center p-1 px-2 rounded cursor-pointer text-[14px] select-none font-bold"
              style={{ background: (invoice !== undefined && invoice !== null) ? linkOnBackground : linkOffBackground }}
              onClick={() => {
                if (invoice !== undefined && invoice !== null)
                  Utils.showContent(invoice);
              }}>
              {languageSet.roomingItemInvoiceButton}
            </div>
          </div>
        </div>
      </div>
      {
        showUploadDialog &&
        <UploadDialog
          title={docNum}
          setShow={setShowUploadDialog}
          data={Props.data}
          refresh={Props.refresh}
          type={uploadType}
        />
      }
    </div>
  )
}