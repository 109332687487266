import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownload } from "@fortawesome/free-solid-svg-icons";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { UserInfo, ScheduleListScroll } from "../../recoil";
import { useRecoilValue, useRecoilState } from "recoil";
import { ROLES } from "../../features/Constant"
import * as HTTPManager from "../../features/HTTPManager";
import * as Utils from "../../features/Utils";
import useScript from "../../hooks/useScript";
import "../styles/CommonStyle.css";
import { Tooltip } from "react-tooltip";
import * as HTTPSettlementManager from "../../features/HTTPSettlementManager";
import { createWorker } from 'tesseract.js';


export default function SettlementPage() {
  useScript("https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js", "sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx");

  const navigate = useNavigate();
  const scrollPosition = window.pageYOffset;
  const [currentScroll, setCurrentScroll] = useRecoilState(ScheduleListScroll);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [tourList, setTourList] = useState([]);
  const [tourFilterList, setTourFilterList] = useState([]);

  const [toursGuide, setToursGuide] = useState([]);
  const [toursGuideFilter, setToursGuideFilter] = useState([]);

  const [toursGuideIncentive, setToursGuideIncentive] = useState([]);
  const [toursGuideETC, setToursGuideETC] = useState([]);

  const [toursGuideMemo, setToursGuideMemo] = useState([]);

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);

  const [userList, setUserList] = useState([]);

  const userInfo = useRecoilValue(UserInfo);

  // 국가 버튼  
  const [phSelected, setPHSelected] = useState(true);
  const [inSelected, setINSelected] = useState(true);
  const [includeFinish, setIncludeFinish] = useState(false);

  const filterYearList = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, "닫기"];
  const [showYearList, setShowYearList] = useState(false);
  const filterMonthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, "닫기"];
  const [showMonthList, setShowMonthList] = useState(false);

  const liCommonCSSUserMenu = "py-1 hover:bg-gray-500 hover:text-[white] select-none";
  const btnCommonCSSUserMenu = "hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none";

  const [uploadFileList, setUploadFileList] = useState([]);
  const [source, setSource] = useState("");
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState(0);
  const [selectedToursGuideId, setSelectedToursGuideId] = useState(0);
  const [selectedGuideId, setSelectedGuideId] = useState(0);
  const [ocrResult, setOCRResult] = useState("");

  const [sendFileType, setSendFileType] = useState("");

  // 가이드별 출력 위한 데이터
  const [guideNameList, setGuideNameList] = useState([]);

  // 가이드 데이터 업데이트 처리
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [updateDataType, setUpdateDataType] = useState("");
  const [updateDataValue, setUpdateDataValue] = useState("");

  const menuList = ["팁 지급내역 업로드", "영수증 확인완료", "가이드 확인완료", "정산완료", "이월처리", "추가영수증 사진보기", "특이사항 확인", "추가인센티브 입력", "비고", "닫기"];
  const [showMenu, setShowMenu] = useState(false);

  const [showReceipt, setShowReceipt] = useState(false);
  const [receiptList, setReceiptList] = useState([]);

  const [showGuideMemo, setShowGuideMemo] = useState(false);

  // 추가 인센티브 입력 처리
  const [showIncentiveInputDialog, setShowIncentiveInputDialog] = useState(false);

  // 메모 입력 처리
  const [showETCInputDialog, setShowETCInputDialog] = useState(false);

  async function convertImageToText(image) {
    const worker = await createWorker(["kor", "eng"]);

    (async () => {
      const {
        data: { text },
      } = await worker.recognize(image);

      setOCRResult(text);
    })();
  }

  const fileUpload = async (target, tourInfo, toursGuideId) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];

        setUploadFileList([
          {
            type: "settlement",
            file: file,
          },
        ]);

        let res = await HTTPSettlementManager.updateSettlementFile({
          accessToken: userInfo.accessToken,
          tourId: tourInfo.id,
          toursGuideId: toursGuideId,
          fileList: [
            {
              type: "settlement",
              file: file,
            },
          ],
        });

        if (res?.data?.result) {
          await fetchData();
        } else {
          alert("업로드 실패!");
        }

        setUploadFileList([]);
      }
    }
  };

  async function handleDownloadFile(fileURL, tourInfo, guideName) {
    let splitList = fileURL.split('/');
    let fileName = splitList[splitList.length - 1];
    let fileExt = fileName.split('.')[1];
    fileName = `${tourInfo.id} ${guideName} ${tourInfo.startDate.slice(5, 10).replace('-', '')} ~ ${tourInfo.endDate.slice(5, 10).replace('-', '')}.${fileExt}`

    fetch(fileURL, {
      method: 'GET',
      headers: {
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName,
        );

        document.body.appendChild(link);

        var answer = window.confirm(`Save ${fileName}?`);
        if (answer) {
          link.click();
          link.parentNode.removeChild(link);
        }
      });
  }

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);

        if (sendFileType === "receipt")
          convertImageToText(newUrl);

        setSource(newUrl);

        setUploadFileList([
          {
            type: sendFileType,
            file: file,
          },
        ]);
      }
    }
  };

  // 데이터 초기화
  async function initalize() {
    setSelectedYear(today.getFullYear());
    setSelectedMonth(today.getMonth());
  }

  // 서버 데이터 수신
  async function fetchData() {
    // 예약정보 요청
    let toursGuide = [];
    let userList = [];

    var response = await HTTPManager.GetUserList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data?.result !== undefined) {
      var sortList = [...response.data.result];
      sortList.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      userList = sortList;
      setUserList(sortList);
    }

    response = await HTTPManager.GetTourReservation({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined && response.data !== undefined) {
      toursGuide = response.data.toursGuide;
      setToursGuide(toursGuide);
    }

    // 투어정보 요청
    response = await HTTPManager.GetTourList({
      accessToken: userInfo.accessToken,
    });

    let initTourList = [];
    if (response !== undefined) {
      setTourList(response.data.result);

      let initYear = selectedYear === 0 ? today.getFullYear() : selectedYear;
      let initMonth = selectedMonth === 0 ? today.getMonth() : selectedMonth;

      initTourList = response.data.result?.filter(tour => (new Date(tour.startDate.replace(" ", "T")).getFullYear() === initYear &&
        new Date(tour.startDate.replace(" ", "T")).getMonth() <= (initMonth - 1) && new Date(tour.startDate.replace(" ", "T")).getMonth() >= (initMonth - 3)));

      // 국가 선택 버튼 확인
      if (!phSelected) {
        initTourList = initTourList?.filter((item) => item.name !== "필리핀");
      }

      if (!inSelected) {
        initTourList = initTourList?.filter((item) => item.name !== "인도네시아");
      }

      userList = sortList;
      filteringTour(initTourList, toursGuide, userList);
    }

    response = await HTTPSettlementManager.getAdditionalIncentiveList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined) {
      setToursGuideIncentive(response.data.result);
    }

    response = await HTTPSettlementManager.getETCList({
      accessToken: userInfo.accessToken,
    });

    if (response !== undefined) {
      setToursGuideETC(response.data.result);
    }

    response = await HTTPManager.GetGuideMemoForAdmin({
      accessToken: userInfo.accessToken
    });

    if (response !== undefined) {
      setToursGuideMemo(response.data.guideMemoList);
    }
  }

  const [allowShow, setAllowShow] = useState(false);

  // useEffect (async () => {}, []) 형태 사용하지 말 것
  // 내부에서 하는 경우 async 함수를 정의해서 호출
  useEffect(() => {
    Utils.allowByPosition(ROLES.ADMIN, userInfo.rolesId)
      .then(async (res) => {
        if (!res) {
          alert("접근 권한이 없습니다.");
          navigate("/", { replace: true });
        }
        else {
          await initalize();
          await fetchData();
          setAllowShow(true);
        }
      })
      .catch((err) => {
        alert(err.message);
      });

  }, []);

  function changeYearOrMonth(year, month, checkFinish) {
    // 선택된 연도 or 달만 표시
    let selectList = tourList.filter(tour =>
      (new Date(tour.startDate.replace(" ", "T")).getFullYear() === year && new Date(tour.startDate.replace(" ", "T")).getMonth() <= (month - 1)) && new Date(tour.startDate.replace(" ", "T")).getMonth() >= (month - 3)
    );

    // 필터 초기화
    filteringTour(selectList, toursGuide, userList, checkFinish, year, month);
    setPHSelected(true);
    setINSelected(true);
  }

  function filteringTour(list, toursGuide, userList, checkFinish, year, month) {
    if (list === undefined) return;

    setTourFilterList(list.filter(item => item.cancel !== 1));

    let initToursGuideList = toursGuide.filter(guideInfo => {
      let tourInfo = list.filter(item => item.cancel !== 1).find(tour => tour.id === guideInfo.tourId);

      if (tourInfo !== undefined && tourInfo !== null) {
        let userInfo = userList?.find(user => user.id === guideInfo.guideId);
        if (userInfo !== undefined && userInfo !== null) {
          guideInfo.name = userInfo.name;
          guideInfo.nameEN = userInfo.nameEN;
          guideInfo.phoneNumber = userInfo.phoneNumber;
          guideInfo.birthDate = userInfo.birthDate;
          return guideInfo;
        }
      }
    });

    if (checkFinish !== undefined) {
      if (!checkFinish)
        initToursGuideList = initToursGuideList.filter(tourGuide => tourGuide.accountFinish !== 1);
    } else {
      if (!includeFinish)
        initToursGuideList = initToursGuideList.filter(tourGuide => tourGuide.accountFinish !== 1);
    }

    initToursGuideList.sort((a, b) => {
      if (a.name < b.name)
        return -1;

      if (a.name > b.name)
        return 1;

      // 시작일 빠른순 정렬
      if (new Date(a.startDate.replace(" ", "T")) > new Date(b.startDate.replace(" ", "T")))
        return 1;
      if (new Date(a.startDate.replace(" ", "T")) < new Date(b.startDate.replace(" ", "T")))
        return -1;
    });

    //이월여부 확인
    initToursGuideList = initToursGuideList.filter(toursGuide => {
      let nextMonth = toursGuide.nextMonth;
      if (nextMonth === undefined || nextMonth === null)
        return toursGuide;
      else {
        if (new Date(nextMonth.replace(" ", "T")).getFullYear() <= year &&
          new Date(nextMonth.replace(" ", "T")).getMonth() <= (month - 1))
          return toursGuide;
      }
    });

    setToursGuideFilter(initToursGuideList);

    let filterList = initToursGuideList.map(item => {
      return item.name;
    });

    let uniqueList = [...new Set(filterList)];
    setGuideNameList(uniqueList);
  }

  const btnMoveMonthClass = "text-3xl pointerhover:hover:cursor-pointer px-4 py-1 pointerhover:hover:bg-gray-200 rounded-full focus:outline-none";

  function moveToRight() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month + 1 > 12) {
      setSelectedYear(selectedYear + 1);
      month = 0;
      year = year + 1;
    }

    setSelectedMonth(month + 1);
    changeYearOrMonth(year, month + 1);
  }

  function moveToLeft() {
    let year = selectedYear;
    let month = selectedMonth;

    if (month - 1 < 1) {
      setSelectedYear(selectedYear - 1);
      month = 13;
      year = year - 1;
    }

    setSelectedMonth(month - 1);
    changeYearOrMonth(year, month - 1);
  }

  function roundDown(number, decimals) {
    decimals = decimals || 0;
    return (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
  }

  function handleDoubleClick(tourId, toursGuideId, type, guideId) {
    setSelectedTourId(tourId);
    setSelectedToursGuideId(toursGuideId);
    setSelectedGuideId(guideId);
    setUpdateDataType(type);
    setShowUpdateDialog(true);
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      await updateData(updateDataType, updateDataValue);
    }
  }

  async function updateData(dataType, dataValue) {
    let res = await HTTPSettlementManager.updateSettlementData({
      accessToken: userInfo.accessToken,
      tourId: selectedTourId,
      toursGuideId: selectedToursGuideId,
      dataType: dataType,
      dataValue: dataValue,
    });

    if (res?.data?.result) {
      setShowUpdateDialog(false);
      setUpdateDataType("");
      setUpdateDataValue("");
      await fetchData();
    } else {
      alert("업데이트 실패!");
    }
  }

  async function handleKeyDownIncentive(event) {
    if (event.key === 'Enter') {
      await insertIncentiveData(updateDataType, updateDataValue);
    }
  }

  async function insertIncentiveData(dataType, dataValue) {
    let res = await HTTPSettlementManager.insertAdditionalIncentive({
      accessToken: userInfo.accessToken,
      toursGuideId: selectedToursGuideId,
      dataType: dataType,
      dataValue: dataValue,
    });

    if (res?.data?.result) {
      setShowIncentiveInputDialog(false);
      setUpdateDataType("");
      setUpdateDataValue("");
      await fetchData();
    } else {
      alert("업데이트 실패!");
    }
  }

  async function handleKeyDownETC(event) {
    if (event.key === 'Enter') {
      await insertETCData(updateDataValue);
    }
  }

  async function insertETCData(dataValue) {
    let res = await HTTPSettlementManager.insertETC({
      accessToken: userInfo.accessToken,
      toursGuideId: selectedToursGuideId,
      dataValue: dataValue,
    });

    if (res?.data?.result) {
      setShowETCInputDialog(false);
      setUpdateDataType("");
      setUpdateDataValue("");
      await fetchData();
    } else {
      alert("업데이트 실패!");
    }
  }

  const idColor = "#43A4F9";
  const phColor = "#FF3A3A";

  return (
    <>
      <div
        className="mt-2 md:mt-14 overflow-y-auto outline-none"
        style={{
          width: "100%",
          backgroundColor: "#fff",
          textAlign: "center",
          justifyContent: 'center',
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            setShowYearList(false);
            setShowMonthList(false);
            e.preventDefault();
          }
        }}
        tabIndex={-1}
      >
        {
          allowShow &&
          <div style={{ width: '100%', height: "100%", alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
            <div style={{
              marginBottom: 5, display: "flex", width: "100%", justifyContent: "center", padding: 10,
            }}
            >
              <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                moveToLeft();
              }} >
                <FontAwesomeIcon icon={faCaretLeft} style={{ color: "#777" }} />
              </span>
              <div
                id="title"
                className="flex flex-row justify-center items-center text-[25px] select-none cursor-pointer">
                <div className="mr-[5px]" onClick={() => {
                  setShowYearList(true);
                }}>{selectedYear}년</div>
                <div onClick={() => {
                  setShowMonthList(true);
                }}>{selectedMonth}월</div>
              </div>
              <span className={btnMoveMonthClass} style={{ fontSize: 25, cursor: "pointer" }} onClick={() => {
                moveToRight();
              }}>
                <FontAwesomeIcon icon={faCaretRight} style={{ color: "#777" }} />
              </span>
            </div>

            <div className="scrollRemoveBox flex flex-row mt-2 md:w-full overflow-auto select-none">
              <span
                className="flex-none items-center rounded-md px-2 py-1 ml-2 text-xs font-medium cursor-pointer"
                style={{
                  background: inSelected ? idColor : "#acacac",
                  color: inSelected ? "#FFFFFF" : "#fefefe",
                }}
                onClick={() => {
                  let initTourList = [];
                  // on > off
                  if (inSelected) {
                    initTourList = tourFilterList?.filter((item) => item.name !== "인도네시아");
                  }
                  // off > on  
                  else {
                    initTourList = tourList?.filter(
                      (item) =>
                        item.name === "인도네시아" &&
                        new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
                        new Date(item.startDate.replace(" ", "T")).getMonth() <= (selectedMonth - 1) && new Date(item.startDate.replace(" ", "T")).getMonth() >= (selectedMonth - 3)
                    );
                  }

                  // off and ph on
                  if (!inSelected && phSelected) {
                    initTourList = tourList?.filter(
                      (item) =>
                        new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
                        new Date(item.startDate.replace(" ", "T")).getMonth() <= (selectedMonth - 1) && new Date(item.startDate.replace(" ", "T")).getMonth() >= (selectedMonth - 3)
                    );
                  }

                  filteringTour(initTourList, toursGuide, userList);
                  setINSelected(!inSelected);
                }}>
                {
                  "인도네시아 " + toursGuideFilter?.filter((toursGuide) => tourFilterList.find(tour => (tour.id === toursGuide.tourId && tour.name === "인도네시아")) !== undefined).length
                }
              </span>

              <span
                className="flex-none items-center rounded-md px-2 py-1 ml-2 text-xs font-medium cursor-pointer select-none"
                style={{
                  background: phSelected ? phColor : "#acacac",
                  color: phSelected ? "#FFFFFF" : "#fefefe",
                }}
                onClick={() => {
                  let initTourList = []

                  if (phSelected) {
                    initTourList = tourFilterList?.filter((item) => item.name !== "필리핀");
                  }
                  else {
                    initTourList = tourList?.filter(
                      (item) =>
                        item.name === "필리핀" &&
                        new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
                        new Date(item.startDate.replace(" ", "T")).getMonth() <= (selectedMonth - 1) && new Date(item.startDate.replace(" ", "T")).getMonth() >= (selectedMonth - 3)
                    );
                  }

                  if (!phSelected && inSelected) {
                    initTourList = tourList?.filter(
                      (item) =>
                        new Date(item.startDate.replace(" ", "T")).getFullYear() === selectedYear &&
                        new Date(item.startDate.replace(" ", "T")).getMonth() <= (selectedMonth - 1) && new Date(item.startDate.replace(" ", "T")).getMonth() >= (selectedMonth - 3)
                    );
                  }

                  filteringTour(initTourList, toursGuide, userList);
                  setPHSelected(!phSelected);
                }}>
                {
                  "필리핀 " + toursGuideFilter?.filter((toursGuide) => tourFilterList.find(tour => (tour.id === toursGuide.tourId && tour.name === "필리핀")) !== undefined).length
                }
              </span>

              <span
                className="flex-none items-center rounded-md px-2 py-1 ml-2 text-xs font-medium cursor-pointer select-none"
                style={{
                  background: includeFinish ? "green" : "#acacac",
                  color: includeFinish ? "#FFFFFF" : "#fefefe",
                }}
                onClick={async () => {
                  changeYearOrMonth(selectedYear, selectedMonth, !includeFinish);
                  setIncludeFinish(!includeFinish);
                }}>
                {
                  "완료포함"
                }
              </span>
            </div>

            <div className="flex flex-row w-full px-2 gap-1 justify-center font-bold">
              <div>인도네시아(회사입금 제외)</div>
              <div>
                {
                  Utils.formatNumber(toursGuideFilter.reduce((a, currentValue) => {
                    let tourInfo = tourFilterList.find(tour => tour.id === currentValue.tourId);

                    if (tourInfo.name === "인도네시아") {
                      let guideFee = Number(currentValue.guideFee);
                      let shopIncentive = Number(currentValue.shopIncentive);
                      let tax = Number(guideFee) * 0.033 + (roundDown(shopIncentive * 0.03, -1) + roundDown(shopIncentive * 0.003, -1));
                      let sum = guideFee + shopIncentive - tax;
                      let preChargeChange = Number(currentValue.preChargeChange);

                      let totalSum = sum - preChargeChange;
                      if (totalSum > 0 && currentValue.receiptCheck === 1)
                        return a + Number(totalSum);
                      else
                        return a + 0;
                    } else {
                      return a + 0;
                    }
                  }, 0))
                }</div>
            </div>

            <div className="flex flex-row w-full px-2 gap-1 justify-center font-bold">
              <div>필리핀(회사입금 제외)</div>
              <div>
                {
                  Utils.formatNumber(toursGuideFilter.reduce((a, currentValue) => {
                    let tourInfo = tourFilterList.find(tour => tour.id === currentValue.tourId);

                    if (tourInfo.name === "필리핀") {
                      let guideFee = Number(currentValue.guideFee);
                      let shopIncentive = Number(currentValue.shopIncentive);
                      let tax = Number(guideFee) * 0.033 + (roundDown(shopIncentive * 0.03, -1) + roundDown(shopIncentive * 0.003, -1));
                      let sum = guideFee + shopIncentive - tax;
                      let preChargeChange = Number(currentValue.preChargeChange);

                      let totalSum = sum - preChargeChange;
                      if (totalSum > 0 && currentValue.receiptCheck === 1)
                        return a + Number(totalSum);
                      else
                        return a + 0;
                    } else {
                      return a + 0;
                    }
                  }, 0))
                }</div>
            </div>

            <div className="flex flex-col w-full gap-10 p-2">
              {
                // TODO 동명이인 처리필요
                guideNameList.map(name => {
                  let initList = toursGuideFilter;
                  let guideTourList = initList.filter(toursGuide => toursGuide.name === name);
                  let lastResult = 0;
                  let guideComplete = true;
                  let guidePhoneNumber = (guideTourList.at(0) !== undefined && guideTourList.at(0) !== null) ? guideTourList.at(0).phoneNumber : "";
                  let etcList = [];

                  return <div key={name} className="flex flex-col w-full text-[14px]">
                    <div className="grid grid-cols-12 w-full bg-[#FFFFFF] border-[1px] divide-x-[1px] divide-black border-black border-b-[1px]">
                      <div className="flex bg-[#D8E4BC] py-1 text-center justify-center">{name}</div>
                      <div className="flex pl-2 items-center">{guidePhoneNumber}</div>
                    </div>
                    <div className="grid grid-cols-12 w-full bg-[#C5D9F1] border-x-[1px] divide-x-[1px] divide-black border-black border-b-[1px]">
                      <div>NO</div>
                      <div>행사번호</div>
                      <div>인원</div>
                      <div>날짜</div>
                      <div>전도금</div>
                      <div className="font-bold">일비합계</div>
                      <div>쇼핑</div>
                      <div>인센티브</div>
                      <div>원천징수(3.3%)</div>
                      <div>합계</div>
                      <div>전도금잔액</div>
                      <div>총 합계</div>
                    </div>
                    {
                      guideTourList.map((data, index) => {
                        let tourInfo = tourFilterList.find(tour => tour.id === data.tourId);
                        let paxStr = tourInfo?.pax.split(/\[|\(|명|\+/);

                        let guideFee = Number(data.guideFee);
                        let shopIncentive = Number(data.shopIncentive);

                        // 추가인센티브 계산
                        let additionalIncentiveList = toursGuideIncentive.filter(item => item.toursGuideId === data.id);
                        let additionalIncentive = additionalIncentiveList.reduce((a, b) => a = a + Number(b.amount), 0);
                        let additionalIncentiveTax = additionalIncentiveList.reduce((a, b) => a = a + (roundDown(Number(b.amount) * 0.03, -1) + roundDown(Number(b.amount) * 0.003, -1)), 0);

                        let tax = Number(data.guideFee) * 0.033 + (roundDown(shopIncentive * 0.03, -1) + roundDown(shopIncentive * 0.003, -1)) + additionalIncentiveTax;

                        let sum = guideFee + shopIncentive + additionalIncentive - tax;
                        let preChargeChange = Number(data.preChargeChange);

                        let totalSum = sum - preChargeChange;
                        let accountFinish = data.accountFinish;
                        let isUploaded = data.settlementURL !== undefined && data.settlementURL !== null;

                        let countryCode = tourInfo.name === "필리핀" ? "(PH)" : tourInfo.name === "인도네시아" ? "(IN)" : "";
                        let countryColor = tourInfo.name === "필리핀" ? `bg-[#FF3A3A]` : tourInfo.name === "인도네시아" ? `bg-[#43A4F9]` : "bg-[#fff]";

                        let toursGuideEtcList = toursGuideETC.filter(item => item.toursGuideId === data.id);

                        for (let i = 0; i < toursGuideEtcList.length; i++) {
                          etcList.push(toursGuideEtcList[i]);
                        }

                        if (data.receiptCheck !== null && data.receiptCheck === 1) {
                          lastResult += totalSum;
                        }

                        if (data.guideCheck === null || data.guideCheck === 0) {
                          guideComplete = false;
                        }

                        return (
                          <div key={data.id}
                            className={`${accountFinish ? "line-through bg-[#dedede]" : ""} grid grid-cols-12 w-full border-x-[1px] divide-x-[1px] divide-black border-black border-b-[1px]`}>
                            <div className="flex items-center justify-center">{index + 1}</div>
                            <div className="flex flex-row items-center justify-center gap-1">
                              <div className={`cursor-pointer select-none font-bold ${countryColor}`}
                                onDoubleClick={async () => {
                                  let res = await HTTPSettlementManager.getReceiptFileList({
                                    accessToken: userInfo.accessToken,
                                    tourId: tourInfo.id,
                                  });

                                  if (res.data !== undefined && res.data !== null) {
                                    setReceiptList(res.data.result);
                                  }

                                  setSelectedTourId(tourInfo.id);
                                  setSelectedToursGuideId(data.id);
                                  setSelectedGuideId(data.guideId);
                                  setShowMenu(true);
                                }}>
                                {data.tourId} {countryCode}
                              </div>
                              {
                                isUploaded &&
                                <div className="rounded-full w-[25px] items-center">
                                  <span className={"cursor-pointer"} onClick={async (e) => {
                                    handleDownloadFile(data.settlementURL, tourInfo, name);
                                  }}>
                                    <FontAwesomeIcon icon={faCloudDownload} className="text-[#dc143c] hover:text-[#dc143c77]" />
                                  </span>
                                </div>
                              }
                            </div>
                            <div className="flex items-center justify-center">{paxStr?.length > 0 && paxStr[0]}</div>
                            <div className="flex items-center justify-center">{data.startDate?.slice(5, 10).replace('-', '.')}~{data.endDate?.slice(5, 10).replace('-', '.')}</div>
                            <div className="cursor-pointer flex items-center justify-center"
                              onDoubleClick={async () => {
                                handleDoubleClick(tourInfo.id, data.id, "preCharge", data.guideId);
                              }}>{Utils.formatNumber(data.preCharge)}</div>
                            <div className="font-bold cursor-pointer flex items-center justify-center"
                              onDoubleClick={async () => {
                                handleDoubleClick(tourInfo.id, data.id, "guideFee", data.guideId);
                              }}
                            >{Utils.formatNumber(guideFee)}</div>
                            <div className="cursor-pointer flex items-center justify-center"
                              onDoubleClick={async () => {
                                handleDoubleClick(tourInfo.id, data.id, "shopCount", data.guideId);
                              }}>{Utils.formatNumber(data.shopCount)}</div>
                            <div className="flex flex-col">
                              <div className="cursor-pointer"
                                onDoubleClick={async () => {
                                  handleDoubleClick(tourInfo.id, data.id, "shopIncentive", data.guideId);
                                }}
                              >{Utils.formatNumber(shopIncentive)}</div>
                              {
                                toursGuideIncentive.filter(item => item.toursGuideId === data.id).map(item => {
                                  return (
                                    <div key={item.id} className="cursor-pointer"
                                      onClick={async () => {
                                        var answer = window.confirm(`${item.name} / ${Utils.formatNumber(item.amount)} 삭제하시겠습니까?`);
                                        if (answer) {
                                          let res = await HTTPSettlementManager.removeAdditionalIncentive({
                                            accessToken: userInfo.accessToken,
                                            id: item.id,
                                          });

                                          if (res?.data?.result) {
                                            await fetchData();
                                          }
                                        }
                                      }}
                                    >
                                      {item.name} {Utils.formatNumber(item.amount)}
                                    </div>
                                  )
                                })
                              }
                            </div>
                            <div className="flex items-center justify-center">{Utils.formatNumber(tax)}</div>
                            <div className="flex items-center justify-center">{Utils.formatNumber(sum)}</div>
                            <div
                              className="cursor-pointer flex items-center justify-center"
                              style={{
                                color: preChargeChange < 0 ? "#FF0000" : "#000000"
                              }}
                              onDoubleClick={async () => {
                                handleDoubleClick(tourInfo.id, data.id, "preChargeChange", data.guideId);
                              }}
                            >{Utils.formatNumber(preChargeChange)}</div>
                            <div className="flex items-center justify-center" style={{
                              color: totalSum < 0 ? "#FF0000" : "#000000"
                            }}>{data.receiptCheck !== null && data.receiptCheck !== 0 && Utils.formatNumber(totalSum)}</div>
                          </div>
                        );
                      })
                    }

                    <div className="grid grid-flow-col grid-cols-4 w-full bg-[#FFFFFF] divide-x-[1px] divide-black border-black border-x-[1px]">
                      <div className="flex bg-[#FFFFFF] py-3 text-center justify-center font-bold">총 합 계</div>
                      <div
                        className="col-span-3 flex font-bold text-[15px] justify-center items-center"
                        style={{
                          background: guideComplete ? "#00B050" : "yellow",
                          color: guideComplete ? lastResult > 0 ? "#FFFFFF" : "#FF9fcf" : "#000000",
                        }}
                      >{Utils.formatNumber(lastResult)}</div>
                    </div>

                    <div className="grid grid-cols-4 w-full bg-[#FFFFFF] border-[1px] divide-x-[1px] divide-black border-black border-b-[1px]">
                      <div className="flex bg-[#FFFFFF] py-10 text-center justify-center">비 고</div>
                      <div className="col-span-3 flex p-1 flex-col justify-start">
                        {
                          etcList?.map(item => {
                            return (
                              <div key={item.id} className="flex normal-case cursor-pointer"
                                onClick={async () => {
                                  var answer = window.confirm(`${item.content} 삭제하시겠습니까?`);
                                  if (answer) {
                                    let res = await HTTPSettlementManager.removeETC({
                                      accessToken: userInfo.accessToken,
                                      id: item.id,
                                    });

                                    if (res?.data?.result) {
                                      await fetchData();
                                    }
                                  }
                                }}
                              >
                                - {item.content}
                              </div>
                            )
                          })
                        }
                        <div className="flex">수고하셨습니다.</div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        }
        {
          // 년 이동 처리
          showYearList &&
          <>
            <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
              <div className="relative w-full m-auto max-w-lg">
                <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  {
                    filterYearList.map((year, index) => {
                      return (
                        <div key={index}
                          className={index !== filterYearList.length - 1
                            ? `border-b ${liCommonCSSUserMenu}`
                            : `${liCommonCSSUserMenu}`
                          }>
                          <button
                            className={btnCommonCSSUserMenu}
                            onClick={() => {
                              if (typeof (year) === "number") {
                                setSelectedYear(year);
                                changeYearOrMonth(year, selectedMonth);

                                setShowYearList(false);
                              } else {
                                setShowYearList(false);
                              }
                            }}
                          >
                            {typeof (year) === "number" ? year + "년" : year}
                          </button>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        }
        {
          // 월 이동 처리
          showMonthList &&
          <>
            <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
              <div className="relative w-full m-auto max-w-lg">
                <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  {
                    filterMonthList.map((month, index) => {
                      return (
                        <div key={index}
                          className={index !== filterMonthList.length - 1
                            ? `border-b ${liCommonCSSUserMenu}`
                            : `${liCommonCSSUserMenu}`
                          }>
                          <button
                            className={btnCommonCSSUserMenu}
                            onClick={() => {
                              if (typeof (month) === "number") {
                                setSelectedMonth(month);
                                changeYearOrMonth(selectedYear, month);

                                setShowMonthList(false);
                              } else {
                                setShowMonthList(false);
                              }
                            }}
                          >
                            {typeof (month) === "number" ? month + "월" : month}
                          </button>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        }
        {
          showMenu &&
          <>
            <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-medium">
              <div className="relative w-full m-auto max-w-lg">
                <div className="border-0 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                  {
                    menuList.map((menu, index) => {
                      // 데이터 검색
                      let tourGuideInfo = toursGuideFilter.find(toursGuide => toursGuide.tourId === selectedTourId);

                      // 메뉴명 설정
                      let receiptCheckStr = tourGuideInfo?.receiptCheck === 1 ? "영수증 확인취소" : menu;
                      let guideCheckStr = tourGuideInfo?.guideCheck === 1 ? "가이드 확인취소" : menu;
                      let finishCheckStr = tourGuideInfo?.accountFinish === 1 ? "정산완료 취소" : menu;
                      let nextMonthCheckStr = (tourGuideInfo?.nextMonth !== null && tourGuideInfo?.nextMonth !== undefined) ? "이월처리 취소" : menu;

                      // 추가영수증 확인
                      let existAddReceipt = receiptList.length > 0;
                      let addReceiptCheckStr = existAddReceipt ? `추가영수증 사진보기 (${receiptList?.length})` : "추가영수증 사진보기";

                      // 특이사항 확인                      
                      let toursGuideMemoList = toursGuideMemo.filter(item => item.tourId === tourGuideInfo?.tourId && item.guideId === tourGuideInfo?.guideId);
                      let existToursGuideMemo = toursGuideMemoList?.length > 0;
                      let toursGuideMemoCheckStr = existToursGuideMemo ? `특이사항 확인 (${toursGuideMemoList?.length})` : "특이사항 확인";

                      if (index === 1) {
                        menu = receiptCheckStr;
                      } else if (index === 2) {
                        menu = guideCheckStr;
                      } else if (index === 3) {
                        menu = finishCheckStr;
                      } else if (index === 4) {
                        menu = nextMonthCheckStr;
                      } else if (index === 5) {
                        menu = addReceiptCheckStr;
                      } else if (index === 6) {
                        menu = toursGuideMemoCheckStr;
                      }

                      return (
                        <div key={index}
                          className={index !== menuList.length - 1
                            ? `border-b py-1 hover:bg-gray-500 hover:text-[white] select-none`
                            : `py-1 hover:bg-gray-500 hover:text-[white] select-none`
                          }>
                          <button
                            className={"relative hover:bg-grey-200 w-full h-full py-2 px-2 text-center focus:outline-none flex flex-row justify-center items-center"}
                            onClick={async () => {
                              switch (menu) {
                                case "팁 지급내역 업로드":
                                  setSendFileType("tip");
                                  setShowImageUpload(true);
                                  break;
                                case "영수증 확인완료":
                                  await updateData("receiptCheck", "1");
                                  break;
                                case "영수증 확인취소":
                                  await updateData("receiptCheck", "0");
                                  break;
                                case "가이드 확인완료":
                                  await updateData("guideCheck", "1");
                                  break;
                                case "가이드 확인취소":
                                  await updateData("guideCheck", "0");
                                  break;
                                case "정산완료":
                                  await updateData("accountFinish", "1");
                                  break;
                                case "이월처리":
                                  await updateData("nextMonth", `${selectedYear}-${selectedMonth < 10 ? "0" + selectedMonth : selectedMonth}-01 00:00:00`);
                                  break;
                                case "이월처리 취소":
                                  await updateData("nextMonth", null);
                                  break;
                                case "정산완료 취소":
                                  await updateData("accountFinish", "0");
                                  break;
                                case addReceiptCheckStr:
                                  setShowReceipt(true);
                                  break;
                                case toursGuideMemoCheckStr:
                                  setShowGuideMemo(true);
                                  break;
                                case "추가인센티브 입력":
                                  setShowIncentiveInputDialog(true);
                                  break;
                                case "비고":
                                  setShowETCInputDialog(true);
                                  break;
                                case "닫기":
                                  setShowMenu(false);
                                  break;
                              }
                            }}
                          >
                            <div>{menu}</div>
                            {
                              ((index === 6 && existToursGuideMemo) || (index === 5) && existAddReceipt) && <div className="w-[8px] h-[8px] bg-[red] rounded-full mt-[-10px] ml-[5px]" />
                            }
                          </button>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        }
        {
          showReceipt &&
          <>
            <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
              <div className="relative h-full w-full py-5">
                <div className="border-0 shadow-lg relative flex flex-col w-full h-full bg-[#dfdfdf] outline-none focus:outline-none normal-case">
                  <span className={`absolute right-6 top-4 bg-[#dfdfdf]`}
                    style={{ fontSize: 20, cursor: "pointer" }}
                    onClick={() => {
                      setShowReceipt(false);
                    }}>
                    <FontAwesomeIcon icon={faXmark} style={{ color: "#000", }} />
                  </span>
                  <div className="flex w-full justify-center my-5">
                    TOTAL {receiptList.length}
                  </div>
                  <div className="flex flex-col px-4">
                    {
                      receiptList.filter(item => item.tourId === selectedTourId && item.toursGuideId === selectedToursGuideId).map((item, index) => {
                        let fileName = item.name;
                        let fileExt = Utils.mimeTypeToExt(item.ext);
                        let originalFileName = item.originalName;
                        let url = "https://ktravelstore.com:9786/images/kts/tsm/receipt/" + fileName + fileExt;

                        return (
                          <div key={item.id} className="flex flex-row gap-3 cursor-pointer p-2 hover:bg-[white]" onClick={() => {
                            if (originalFileName.endsWith(".jpeg") || originalFileName.endsWith(".jpg") || originalFileName.endsWith(".png") || originalFileName.endsWith(".pdf"))
                              window.open(url, "_blank");
                          }}>
                            <div>{index + 1}.</div>
                            <div>{originalFileName}</div>
                            <div>{item.createdAt}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        }
        {
          showGuideMemo &&
          <>
            <div className="md:top-14 mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[105] outline-none focus:outline-none font-medium">
              <div className="relative h-full w-full py-5">
                <div className="border-0 shadow-lg relative flex flex-col w-full h-full bg-[#dfdfdf] outline-none focus:outline-none normal-case">
                  <span className={`absolute right-6 top-4 bg-[#dfdfdf]`}
                    style={{ fontSize: 20, cursor: "pointer" }}
                    onClick={() => {
                      setShowGuideMemo(false);
                    }}>
                    <FontAwesomeIcon icon={faXmark} style={{ color: "#000", }} />
                  </span>
                  <div className="flex w-full justify-center my-5">
                    TOTAL {toursGuideMemo.filter(item => item.tourId === selectedTourId && item.guideId === selectedGuideId)?.length}
                  </div>
                  <div className="flex flex-col px-4 gap-2">
                    {
                      toursGuideMemo.filter(item => item.tourId === selectedTourId && item.guideId === selectedGuideId)?.map((item, index) => {
                        return (
                          <div key={item.id} className="flex">
                            {index + 1}. {item.contents}
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </>
        }
        {
          showImageUpload ? (
            <>
              <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
                <div className="relative w-full my-6 mx-auto max-w-lg">
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none">
                    <input
                      className="hidden"
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      capture="environment"
                      onChange={(e) => {
                        setOCRResult("");
                        handleCapture(e.target);
                      }}
                    />
                    <label htmlFor="icon-button-file">
                      <span className={"cursor-pointer px-2 py-1"}>
                        <h2 className="text-base font-bold select-none">사진선택</h2>
                      </span>
                    </label>
                    {
                      source &&
                      <div className="flex justify-center border-red-100 select-none">
                        <img src={source} alt={"snap"} className=""></img>
                      </div>
                    }
                    <div className="flex flex-wrap my-2 px-2">{ocrResult}</div>

                    <div className="flex flex-row">
                      {
                        source &&
                        <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"}>
                          <div className={"select-none text-[deepskyblue] font-bold"} onClick={async () => {

                            if (sendFileType === "tip") {
                              let res = await HTTPSettlementManager.updateTipFile({
                                accessToken: userInfo.accessToken,
                                tourId: selectedTourId,
                                toursGuideId: selectedToursGuideId,
                                fileList: uploadFileList,
                              });

                              if (res?.data?.result) {
                                setShowImageUpload(false);
                                setSource("");
                                setOCRResult("");
                                setUploadFileList([]);
                                await fetchData();
                              } else {
                                alert("업로드 실패!");
                              }
                            } else if (sendFileType === "receipt") {
                              let res = await HTTPSettlementManager.updateReceiptFile({
                                accessToken: userInfo.accessToken,
                                tourId: selectedTourId,
                                toursGuideId: selectedToursGuideId,
                                fileList: uploadFileList,
                              });

                              if (res?.data?.result) {
                                setShowImageUpload(false);
                                setSource("");
                                setOCRResult("");
                                setUploadFileList([]);
                                await fetchData();
                              } else {
                                alert("업로드 실패!");
                              }
                            }
                          }}>업로드</div>
                        </span>
                      }
                      <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"}>
                        <div className={"select-none text-[red] font-bold"} onClick={() => {
                          setShowImageUpload(false);
                          setSource("");
                          setOCRResult("");
                          setUploadFileList([]);
                        }}>취소</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
            </>
          ) : null
        }
        {
          showUpdateDialog && <>
            <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
              <div className="relative w-full my-6 mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none">
                  <input
                    className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-4 pl-10 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
                    type="text"
                    onChange={(e) => {
                      setUpdateDataValue(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                  <div className="flex flex-row">
                    <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[deepskyblue] font-bold"}
                        onClick={async () => {
                          await updateData(updateDataType, updateDataValue);
                        }}>업데이트</div>
                    </span>
                    <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[red] font-bold"} onClick={() => {
                        setShowUpdateDialog(false);
                        setUpdateDataType("");
                        setUpdateDataValue("");
                      }}>취소</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        }
        {
          showIncentiveInputDialog && <>
            <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
              <div className="relative w-full mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none px-5 pt-5">
                  <input
                    className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-4 pl-10 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
                    type="text"
                    placeholder="종류"
                    onChange={(e) => {
                      setUpdateDataType(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDown(e)}
                  />
                  <input
                    className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-4 pl-10 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
                    type="text"
                    placeholder="금액"
                    onChange={(e) => {
                      setUpdateDataValue(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDownIncentive(e)}
                  />
                  <div className="flex flex-row">
                    <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[deepskyblue] font-bold"}
                        onClick={async () => {
                          insertIncentiveData(updateDataType, updateDataValue);
                        }}>입력</div>
                    </span>
                    <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[red] font-bold"} onClick={() => {
                        setShowIncentiveInputDialog(false);
                        setUpdateDataType("");
                        setUpdateDataValue("");
                      }}>취소</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        }
        {
          showETCInputDialog && <>
            <div className="scrollRemoveBox mx-6 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[150] outline-none focus:outline-none font-medium">
              <div className="relative w-full mx-auto max-w-lg">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#efefef] outline-none focus:outline-none px-5 pt-5">
                  <input
                    className="rounded placeholder:text-slate-400 block bg-white border border-slate-300 py-4 pl-10 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 mb-2"
                    type="text"
                    placeholder="내용"
                    onChange={(e) => {
                      setUpdateDataValue(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDownETC(e)}
                  />
                  <div className="flex flex-row">
                    <span className={"flex w-full cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[deepskyblue] font-bold"}
                        onClick={async () => {
                          insertETCData(updateDataValue);
                        }}>입력</div>
                    </span>
                    <span className={"flex w-full  cursor-pointer px-2 py-3 justify-center"}>
                      <div className={"select-none text-[red] font-bold"} onClick={() => {
                        setShowETCInputDialog(false);
                        setUpdateDataType("");
                        setUpdateDataValue("");
                      }}>취소</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-[99] bg-black"></div>
          </>
        }
      </div>
    </>
  )
}